import Explorer from "./Explorer.jsx";

import icon from "./icon.png";

const ExplorerApp = {
	name: "Fichiers",
	icon: icon,
	ext: "app",
	path: "Explorer",
	appName: "Explorer",
	hidden: true,
	datagrid: {
		y: 20,
		w: 1280,
		h: 800,
		minW: 230,
	},
	component: Explorer,
};

export default ExplorerApp;
