import { PROJECTS_URL } from "../../System/Paths";
import icon from "./icon.png";

const LinesApp = {
	name: "Lines",
	icon: icon,
	url: PROJECTS_URL + "LinesDots",
	ext: "web",
	path: "Lines",
	datagrid: {
		w: 500,
		h: 500,
	},
};

export default LinesApp;
