export const ft_inrad = (angle) => angle * (Math.PI / 180);

export const ft_indeg = (angle) => angle * (180.0 / Math.PI);

export const ft_to_360 = (angle) => {
	if (angle === 0) angle = 0.1;
	if (angle < 0) return 2.0 * Math.PI + angle;
	else if (angle > 2 * Math.PI) return angle - 2.0 * Math.PI;
	return angle;
};
