class UserController {
	#Storage = null;
	#Root = {
		name: "",
		path: "",
		abs_path: "",
		type: "folder",
		files: [],
		pass: "",
	};
	#Image = "";
	#AppsStorage = [];
	#Appearance = {
		wallpaper: null,
		theme: "light",
	};
	#Dock = {
		files: [],
	};

	constructor(props) {
		this.#Storage = props.storage;
		this.#Root = {
			name: props.name,
			path: props.path,
			abs_path: props.abs_path,
			type: props.type,
			hidden: props.hidden,
			pass: props.pass,
			files: props.files,
		};
		this.#Appearance = props.appearance || this.#Appearance;
		this.#Image = props.image;
		this.#Dock = props.dock;
		this.#AppsStorage = props.appsStorage;
	}

	getAppStorage(app_path) {
		for (let x in this.#AppsStorage)
			if (this.#AppsStorage[x].path === app_path)
				return this.#AppsStorage[x];
		return false;
	}

	loadAppStorage(app) {
		let strg = this.getAppStorage(app.path);
		if (strg) return strg;
		else
			return {
				path: app.path,
				name: app.name,
				datas: {},
			};
	}

	getDock() {
		return this.#Dock;
	}

	absPath = () => this.#Root.abs_path;

	appearance = (setAppearance) => {
		if (setAppearance) {
			this.#Appearance = setAppearance;
			this.save();
		}
		return this.#Appearance;
	};

	wallpaper = (setWp) => {
		if (typeof setWp !== "undefined") {
			this.#Appearance.wallpaper = setWp;
			this.save();
		}
		return this.#Appearance.wallpaper;
	};

	save = () => {
		this.#Storage.write(this.#Root.abs_path, this.values());
		this.#Storage.forceReload();
	};

	values = () => ({
		...this.#Root,
		image: this.#Image,
		appsStorage: this.#AppsStorage,
		appearance: this.#Appearance,
		dock: this.#Dock,
	});

	name = () => this.#Root.name;
}

export default UserController;
