import React, { useEffect } from "react";
import "./Boot.css";
// Font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPizzaSlice } from "@fortawesome/free-solid-svg-icons";

const Boot = (props) => {
	useEffect(() => {
		const timeout = props.duration ? props.duration * 1000 : 10000;
		setTimeout(() => {
			if (props.onBoot) props.onBoot();
		}, timeout);
	}, [props]);

	return (
		<div className="Boot fullscreen-cont">
			<FontAwesomeIcon className="mb-3 logo" icon={faPizzaSlice} />
			<div
				className="progress-bar"
				style={{
					animationDuration: props.duration
						? props.duration + "s"
						: "10s",
				}}
			></div>
			{props.text.length && <div className="text mt-2">{props.text}</div>}
		</div>
	);
};

export default Boot;
