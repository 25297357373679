import icon from "./icon.png";

const PsykalaApp = {
	name: "Psykala",
	icon: icon,
	url: "https://psykala.com",
	path: "Psykala",
	ext: "web",
	type: "file",
	datagrid: {
		w: 1920,
		h: 1080,
	},
};

export default PsykalaApp;
