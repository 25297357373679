import React, { Component, useState } from "react";

// Font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faMinus,
	faPlus,
	faTimes,
	faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import Locales from "../../../Locales/Locales";
import "./Window.css";
import Executer from "../../../System/Executer/Executer";

function Window(props) {
	const WinComponent = Executer(props);
	const [isLoaded, setIsLoaded] = useState(false);
	const [title, setTitle] = useState(props.name);
	const [isInitied, setIsInitied] = useState(false);
	const [History, setHistory] = useState([]);

	let history_call = () => true;

	React.useEffect(() => {
		if (!props.isFocused && !isInitied) {
			if (props.onClick) props.onClick();
			setIsInitied(true);
		}
	}, [isInitied, setIsInitied, props]);

	function close(e) {
		e.preventDefault();
		e.stopPropagation();
		if (props.handleClose) props.handleClose(props.winId);
	}

	function addToHistory(call) {
		let history = History;

		history.push(call);
		setHistory(history);
	}

	function callHistory(e) {
		e.preventDefault();
		e.stopPropagation();
		let history = History;
		if (!History.length) return false;
		let history_call = History[History.length - 1];
		if (history_call) {
			history_call();
			history.pop();
			setHistory(history);
		}
	}

	return (
		<div
			className={
				"Window" +
				(props.isFocused ? " focused" : "") +
				(isLoaded ? " loaded" : "")
			}
			key={props.index}
			onClick={props.onClick}
		>
			<div className="topbar d-flex align-items-center">
				<div className="d-flex align-items-center me-1">
					<div
						className="window-btn close"
						onTouchEnd={close}
						onClick={close}
					>
						<FontAwesomeIcon icon={faTimes} />
					</div>
					<div className="window-btn reduce">
						<FontAwesomeIcon icon={faMinus} />
					</div>
					<div
						className="window-btn maximize"
						onClick={props.handleMaximize}
					>
						<FontAwesomeIcon icon={faPlus} />
					</div>
				</div>
				{props.history && (
					<div className="d-flex history-btns me-2">
						<div
							className={`arrow ${
								!History.length ? "disabled" : ""
							}`}
							onClick={callHistory}
						>
							<FontAwesomeIcon icon={faChevronLeft} />
						</div>
						{/* <div className="arrow"><FontAwesomeIcon icon={faChevronRight} onClick={() => history("next")}/></div> */}
					</div>
				)}
				<div className="title me-2" title={"WinId: " + props.winId}>
					{title}
				</div>
			</div>
			<div className="window-cont">
				{!isLoaded && (
					<div className="window-load">
						<div className="loading-spinner"></div>
						<div>{Locales("Commons", "LOADING")}</div>
					</div>
				)}
				{WinComponent?.Opener ? (
					<WinComponent.Opener
						{...props}
						{...WinComponent.props.childProps}
						setTitle={setTitle}
						launch={props.launch}
						disk={props.disk}
						args={WinComponent.props}
						isLoaded={isLoaded}
						isFocused={props.isFocused}
						story={props.story}
						history={{
							push: addToHistory,
						}}
						backBtnCall={history_call}
						endLoad={() => setIsLoaded(true)}
						appProps={props}
					/>
				) : (
					<ErrorWindow
						close={close}
						endLoad={() => setIsLoaded(true)}
					/>
				)}
			</div>
		</div>
	);
}

class ErrorWindow extends Component {
	componentDidMount() {
		this.props.endLoad();
	}

	render() {
		return (
			<div className="p-5">
				<div className="w-100 text-center">Erreur:</div>
				Aucune application ne peux ouvrir ce type de fichier pour le
				moment.
				<br />
				Veuillez attendre une prochaine mise à jour, merci.
				<div
					className="error-window-btn mt-2"
					onClick={this.props.close}
				>
					Fermer
				</div>
			</div>
		);
	}
}

export default Window;
