import React, { Component } from "react";
import Boot from "../../../components/Boot/Boot";
import "./Glitches.css";

export default class Glitches extends Component {
	constructor(props) {
		super(props);
		this.interv = null;
		this.ref = React.createRef();
		this.state = {
			blueScreen: false,
		};

		this.genGlitches = this.genGlitches.bind(this);
		this.handlePress = this.handlePress.bind(this);
	}

	componentDidMount() {
		this.canvas = this.ref?.current;
		this.canvas.width = document.body.clientWidth;
		this.canvas.height = document.body.clientHeight;

		this.ctx = this.canvas.getContext("2d");

		// this.genGlitches()
		setTimeout(() => {
			this.interv = setInterval(this.genGlitches, 10);
			this.timeout = setTimeout(() => {
				clearInterval(this.interv);
				setTimeout(() => {
					this.setState(
						{
							blueScreen: true,
						},
						() => {
							window.addEventListener(
								"keydown",
								this.handlePress
							);
						}
					);
				}, 400);
			}, 600);
		}, 400);
	}

	componentWillUnmount() {
		window.removeEventListener("keydown", this.handlePress);
	}

	handlePress(e) {
		this.props.story.setIsLogged(false);
		this.props.story.setTempCont(
			<Boot
				disk={this.props.disk}
				story={this.props.story}
				text="Restauration de la sauvegarde du 01/01/2022"
				onBoot={() => {
					this.props.story.step("konami", true);
					let disk = this.props.disk.read("/");
					disk.story = this.props.story.values();
					// disk.version = 3;
					this.props.disk.write("/", disk);
					this.props.story.setTempCont(false);
				}}
			/>
		);
	}

	genGlitches() {
		let random = rand(10, 100, true);
		this.ctx.clearRect(
			0,
			0,
			document.body.clientWidth,
			document.body.clientHeight
		);
		for (let i = 0; i < random; i++) {
			let x = rand(-200, document.body.clientWidth, true);
			let y = rand(-200, document.body.clientHeight, true);
			let color =
				"rgb(" +
				rand(0, 255) +
				"," +
				rand(0, 255) +
				"," +
				rand(0, 255) +
				")";

			this.glitch(x, y, 10, 10, rand(1, 100), rand(1, 10), color);
		}
	}

	glitch(posX, posY, length, height, sizeX, sizeY, color) {
		let ctx = this.ctx;

		ctx.fillStyle = color;

		for (let x = 0; x < length; x++) {
			for (let y = 0; y < height; y++) {
				ctx.fillRect(posX + x * sizeX, posY + y * sizeY, sizeX, sizeY);
			}
		}
	}

	render() {
		return (
			<div className="Glitches">
				{!this.state.blueScreen && (
					<canvas
						className="glitches-cont"
						ref={this.ref}
						onClick={this.genGlitches}
					/>
				)}
				{this.state.blueScreen && (
					<div className="blue-screen">
						Une erreur est survenue avec le fichier
						<br />
						"/apps/Gamebox/package/config/..."
						<br />
						<br />
						Appuyez sur une touche pour continuer
					</div>
				)}
			</div>
		);
	}
}

function rand(min, max, rounded) {
	let val = (max - min) * Math.random() + min;
	return rounded ? Math.round(val) : val;
}
