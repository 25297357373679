import React from "react";

import "./ExplorerFile.css";
function ExplorerFile({ file, onClick }) {
	return (
		<div className="file" onClick={onClick}>
			<div
				className="icon file-icon"
				style={{
					backgroundImage: file.icon ? "url(" + file.icon + ")" : "",
				}}
			></div>
			<div className="name file-name">{file.name}</div>
		</div>
	);
}

export default ExplorerFile;
