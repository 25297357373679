import ExplorerApp from "./Explorer/ExplorerApp";
import GameBoxApp from "./Gamebox/GameBoxApp";
import NotesApp from "./Notes/NotesApp";
// import MusicApp from "./Music/MusicApp";
import SettingsApp from "./Settings/SettingsApp";
import PsykalaApp from "./Psykala/PsykalaApp";
import ShadesApp from "./Shades/ShadesApp";
import LinesApp from "./Lines/LinesApp";
import SnakeApp from "./Snake/SnakeApp";
import PongApp from "./Pong/PongApp";
// import Game2048App from "./2048/Game2048App";

export const Apps = [
	ExplorerApp,
	NotesApp,
	// MusicApp,
	SettingsApp,
	PsykalaApp,
	ShadesApp,
	LinesApp,
];

export const Games = [
	GameBoxApp,
	SnakeApp,
	PongApp,
	// Game2048App
];

const ApplicationList = [...Apps, ...Games];

const getApp = (name) => {
	let found = ApplicationList.find((a) => a.appName === name);
	return found !== -1 ? found : false;
};

export default getApp;
