import StoryNotes from "./StoryNotes";

export default class StoryManager {
	#steps = {};
	setTempCont = false;

	constructor(steps, setTempCont, setIsLogged) {
		this.#steps = steps;
		this.setTempCont = setTempCont;
		this.setIsLogged = setIsLogged;
	}

	loadSteps(steps) {
		this.#steps = steps;
	}

	checkSteps(steps) {
		let to_check = steps;
		if (typeof steps !== "object") to_check = [steps];
		for (let x in to_check) if (!this.step(to_check[x])) return false;
		return true;
	}

	step(stepId, setValue) {
		if (typeof setValue !== "undefined") this.#steps[stepId] = setValue;
		return this.#steps[stepId];
	}

	getNotes() {
		let ret = [];
		let stories = StoryNotes;

		for (let x in stories) {
			if (stories[x].steps) {
				if (this.checkSteps(stories[x].steps)) {
					let story = stories[x];
					story.modified = stories.length - x;
					story.id = x;
					ret.push(story);
				} else break;
			}
		}
		return ret.reverse();
	}

	values() {
		return this.#steps;
	}
}
