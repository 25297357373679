export const Locales_fr_FR = {
	Code: "fr-FR",
	CodeMin: "fr",

	Server: {
		CONNECTING: "Connexion au serveur",
		PING_ERROR: "Erreur de connexion au serveur",
		TIMEOUT: "Le serveur a mis trop de temps à répondre",
		RETRY_LATER: "Veuillez re-essayer ultérieurement, merci",
	},

	Desktop: {
		LOAD_ERROR: "Une erreur est survenue lors du chargement du Desktop",
		ERROR_404: "Erreur 404",
		PAGE_NOT_FOUND: "La page demandée n'existe pas",
	},

	Commons: {
		WELCOME: "Bienvenue",
		LOADING: "Chargement",
	},

	Dates: {
		DAY_TITLE_FORMAT: "dddd D MMMM YYYY",
	},
};
