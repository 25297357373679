import React from "react";

import { Locales_en_EN } from "./Strings/en-EN.jsx";
import { Locales_fr_FR } from "./Strings/fr-FR.jsx";
import "./Locales.css";

const DEFAULT_CODE = "en-EN";
const Items = [
	{
		minCode: "fr",
		code: "fr-FR",
		title: "Français",
		locales: Locales_fr_FR,
	},
	{
		minCode: "en",
		code: "en-EN",
		title: "English",
		flag_code: "US",
		locales: Locales_en_EN,
	},
];

/**
 * @description Wrap the App to enable quick language change
 * @prop {string} code ex: en-EN, fr-FR ...
 * @example
 * function App() {
 *   const [code, setCode] = useState("fr-FR");
 *   return (
 *     <LocalesWrapper code={code}>
 *       <div>{Locales("Code")}</div>
 *     </LocalesWrapper>
 *   );
 * }
 */
function LocalesWrapper(props) {
	let code = props.code ? props.code : DEFAULT_CODE;

	localStorage.setItem("lang-code", code);

	return <div code={code}>{props.children}</div>;
}

/**
 * @description Display languages menu
 * @prop {function} handleChange
 * @example
 *
 * function App() {
 *   const [code, setCode] = useState("fr-FR");
 *   return (
 *     <LocalesWrapper code={code}>
 *       <div>{Locales("Code")}</div>
 *       <LocalesMenu handleChange={setCode}/>
 *     </LocalesWrapper>
 *   );
 * }
 */
function LocalesMenu({ handleChange }) {
	const flags_url =
		"https://purecatamphetamine.github.io/country-flag-icons/3x2/";

	function handleClick(code) {
		if (handleChange) handleChange(code);
	}

	return (
		<div className="locales-menu-cont">
			{Items.map((lang) => {
				let flag_code;
				if (lang.flag_code) flag_code = lang.flag_code;
				else flag_code = lang.code.split("-")[1];
				return (
					<div className="locales-item" key={lang.code}>
						<a
							href={"#" + lang.code}
							onClick={() => handleClick(lang.code)}
						>
							<div className={"flag-icon me-2"}>
								<img
									alt={"flag-icon-" + lang.code}
									src={flags_url + flag_code + ".svg"}
								/>
							</div>
							<div>{lang.title}</div>
						</a>
					</div>
				);
			})}
		</div>
	);
}

/**
 * @description Get string in current language
 * @param {string} group
 * @param {string} id
 * @param {string} [code] force lang code for a string if provided
 * @example
 *
 * function MyComponent() {
 *   return (
 *     <div>
 *       <div>{Locales("Code")}</div> // "fr-FR"
 *       <div>{Locales("Commons", "WELCOME")}</div> // "Bonjour"
 *       <div>{Locales("Commons", "WELCOME", "en-EN")}</div> // "Hello"
 *     </div>
 *   );
 * }
 */
function Locales(group, id, code) {
	if (!code) {
		code = getSessCode();
		if (!code) code = DEFAULT_CODE;
	}

	const LocalesStrings = getStrings(code);

	function getSessCode() {
		let sess_code = localStorage.getItem("lang-code");
		if (sess_code) return sess_code;
		return false;
	}

	if (group && LocalesStrings[group]) {
		if (id && LocalesStrings[group][id]) return LocalesStrings[group][id];
		else if (typeof LocalesStrings[group] !== "object")
			return LocalesStrings[group];
	}
	return "{ " + code + "." + group + "." + id + " }";
}

function getStrings(code) {
	if (!code) return Locales_en_EN;
	for (let x in Items)
		if (Items[x].code === code || Items[x].minCode === code)
			return Items[x].locales;
	return false;
}

export { LocalesWrapper, Locales, LocalesMenu };
export default Locales;
