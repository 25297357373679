import React, { Component } from "react";
import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import "./WindowsManager.css";
import Window from "./Window/Window";
import { calculateMagnetPosition } from "./Magnet";

class WindowsManager extends Component {
	constructor(props) {
		super(props);
		this.state = {
			focused: null,
			needRefocus: false,
			lastUpdate: Date.now(),
			magnet: false,
			// {
			// 	x: 0,
			// 	y: 0,
			// 	w: 0,
			// 	h: 0,
			//  type: "left" | "right" | "top",
			// }
		};
		this.layout_ref = React.createRef();
		this.maximize = this.maximize.bind(this);
		this.handleLayoutChange = this.handleLayoutChange.bind(this);
		this.handleMagnet = this.handleMagnet.bind(this);
		this.setActive = this.setActive.bind(this);
	}

	componentDidUpdate() {
		if (!this.props.windows?.length) return false;
		let last = this.props.windows[this.props.windows?.length - 1];
		let propId = this.props.activeWin?.winId;
		if (propId && propId !== this.state.focused)
			this.setState({ focused: propId });
		else if (this.props.windows?.length && !this.state.focused)
			this.setState({ focused: last.winId });
	}

	setActive(win) {
		if (this.state.focused === win.winId) return false;
		this.setState(
			{
				focused: win.winId,
			},
			() => {
				this.props.setActive(win);
			}
		);
	}

	maximize(item) {
		this.setState({
			lastUpdate: Date.now(),
		});

		let cont = this.props.contRef?.current;

		this.props.processUpdate([
			{
				winId: item.winId,
				datagrid: {
					...item.datagrid,
					x: 0,
					y: 0,
					w: cont.clientWidth,
					h: cont.clientHeight,
					maximized: true,
				},
			},
		]);
	}

	handleLayoutChange(lay, oldItem, newItem, placeholder, e, element) {
		// console.log(
		// 	"handleLayoutChange",
		// 	lay,
		// 	oldItem,
		// 	newItem,
		// 	placeholder,
		// 	e,
		// 	element
		// );
		let processUpdateList = [];
		// console.log("END UPDATE", lay);

		lay.forEach((a) => {
			let process = this.props.windows.find((b) => b.winId === a.i);
			if (!process) return false;
			let magnet =
				this.state.magnet?.winId === a.i ? this.state.magnet : {};
			// console.log("magnet", magnet);
			if (magnet.type) {
				// console.log("SET MAGNET for", process.winId, magnet);
				// console.log("SET A", a);
			}
			let merged = { ...a, ...magnet };
			// console.log("SET MERGED", merged);
			processUpdateList.push({
				winId: process.winId,
				...a,
				datagrid: merged,
			});
		});

		if (this.state.magnet)
			this.setState({ magnet: false }, () => {
				this.props.processUpdate(processUpdateList);
			});
		else this.props.processUpdate(processUpdateList);
	}

	handleMagnet(layout, oldItem, newItem, placeholder, e, element) {
		let cont = this.props.contRef?.current;
		if (!cont) return false;
		const rect = element.getBoundingClientRect();
		let magnet = calculateMagnetPosition(rect.x, rect.y, 10, cont);
		if (magnet) {
			if (this.state.magnet?.type !== magnet.type) {
				this.setState({ magnet: { ...magnet, winId: newItem.i } });
			}
		} else if (this.state.magnet) this.setState({ magnet: false });
	}

	render() {
		// console.log(this.state.magnet);
		let windows = this.props.windows;
		return (
			<div
				ref={this.props.contRef}
				lastupdate={this.state.lastUpdate}
				className="WindowManager w-100 h-100"
			>
				{this.state.magnet?.type && (
					<div
						className="magnet"
						style={{
							left: this.state.magnet.x,
							top: this.state.magnet.y,
							width: this.state.magnet.w,
							height: this.state.magnet.h,
						}}
					></div>
				)}
				<GridLayout
					className="layout h-100"
					cols={document.body.clientWidth}
					rowHeight={1}
					margin={[0, 0]}
					width={document.body.clientWidth}
					compactType={"vertical"}
					allowOverlap={true}
					isDraggable={true}
					isResizable={true}
					draggableHandle=".topbar"
					lastUpdate={this.state.lastUpdate}
					layout={windows.map((a) => a.datagrid)}
					onLayoutChange={this.handleLayoutChange}
					onDragStop={this.handleLayoutChange}
					onDrag={this.handleMagnet}
					onResizeStop={this.handleLayoutChange}
					ref={this.layout_ref}
				>
					{windows.map((win, index) => {
						let isFocused = win.winId === this.state.focused;
						return (
							<div
								winid={win.winId}
								key={win.winId}
								data-grid={win.datagrid}
								style={{
									zIndex: isFocused ? 1 : 0,
								}}
							>
								<Window
									datagrid={win.datagrid}
									story={this.props.story}
									isFocused={isFocused}
									launch={this.props.launch}
									onClick={() => this.setActive(win)}
									disk={this.props.disk}
									handleClose={() => {
										if (isFocused)
											this.setState({ focused: null });
										this.props.close(win.winId);
									}}
									handleMaximize={() => this.maximize(win)}
									{...win}
								/>
							</div>
						);
					})}
				</GridLayout>
			</div>
		);
	}
}

export default WindowsManager;
