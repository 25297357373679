import React from "react";
import { Draggable } from "react-beautiful-dnd";
// Font awesome
import "./Icon.css";
import Icon from "./Icon";

function DraggableIcon(props) {
	return (
		<Draggable draggableId={props.UUID} index={props.index + 1}>
			{(provided, snapshot) => {
				if (snapshot.isDragging) {
					provided.draggableProps.style.left = "auto";
					provided.draggableProps.style.top = "auto";
				}
				return (
					<div
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
					>
						<Icon {...props} />
					</div>
				);
			}}
		</Draggable>
	);
}

export default DraggableIcon;
