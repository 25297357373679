import React, { Component } from "react";
import Cube3D from "./Cube3D/Cube3D";
import KeyManager from "./Cube3D/srcs/KeyManager";
import "./Gamebox.css";
import Glitches from "./Glitches/Glitches";

export default class Gamebox extends Component {
	constructor(props) {
		super(props);
		this.keyManager = new KeyManager(
			[
				{
					code: "KeyQ",
					callback: () => this.setState({ a: true }),
					onKeyUp: () => this.setState({ a: false }),
				},
				{
					code: "KeyB",
					callback: () => this.setState({ b: true }),
					onKeyUp: () => this.setState({ b: false }),
				},
				{
					code: "ArrowUp",
					callback: () => this.setState({ up: true }),
					onKeyUp: () => this.setState({ up: false }),
				},
				{
					code: "ArrowDown",
					callback: () => this.setState({ down: true }),
					onKeyUp: () => this.setState({ down: false }),
				},
				{
					code: "ArrowLeft",
					callback: () => this.setState({ left: true }),
					onKeyUp: () => this.setState({ left: false }),
				},
				{
					code: "ArrowRight",
					callback: () => this.setState({ right: true }),
					onKeyUp: () => this.setState({ right: false }),
				},
			],
			[
				"ArrowUp",
				"ArrowUp",
				"ArrowDown",
				"ArrowDown",
				"ArrowLeft",
				"ArrowRight",
				"ArrowLeft",
				"ArrowRight",
				"KeyB",
				"KeyQ",
			],
			() => {
				let elem = document.createElement("audio");
				elem.src = "assets/sounds/coin/coin-glitches.m4a";
				elem.autoplay = true;
				this.Gbref?.current?.appendChild(elem);
				elem.onended = () => {
					this.Gbref?.current?.removeChild(elem);
				};
				props.story.setTempCont(
					<Glitches story={props.story} disk={props.disk} />
				);
			}
		);

		this.state = {
			up: false,
			down: false,
			left: false,
			right: false,
			a: false,
			b: false,
		};

		this.Gbref = React.createRef();

		this.handlePress = this.handlePress.bind(this);
		this.handleRelease = this.handleRelease.bind(this);
	}

	componentDidMount() {
		if (!this.props.isLoaded) this.props.endLoad();
		window.addEventListener("keydown", this.handlePress);
		window.addEventListener("keyup", this.handleRelease);
	}

	handlePress(e) {
		if (this.props.isFocused) this.keyManager.handlePress(e);
	}

	handleRelease(e) {
		if (this.props.isFocused) this.keyManager.handleRelease(e);
	}

	componentWillUnmount() {
		window.removeEventListener("keydown", this.handlePress);
		window.removeEventListener("keyup", this.handleRelease);
	}

	render() {
		return (
			<>
				<div className="Gamebox" ref={this.Gbref}>
					<div className="case">
						<div className="screen-cont">
							<div className="screen d-flex align-items-center justify-content-center">
								<Cube3D isFocused={this.props.isFocused} />
							</div>
						</div>

						<div className="buttons-cont d-flex justify-content-between align-items-center">
							<div className="arrows">
								<div
									className={
										"arrow up" +
										(this.state.up ? " pressed" : "")
									}
								/>
								<div className="d-flex w-100 justify-content-center align-items-center">
									<div
										className={
											"arrow left" +
											(this.state.left ? " pressed" : "")
										}
									/>
									<div className="arrow-center"></div>
									<div
										className={
											"arrow right" +
											(this.state.right ? " pressed" : "")
										}
									/>
								</div>
								<div
									className={
										"arrow down" +
										(this.state.down ? " pressed" : "")
									}
								/>
							</div>
							<div className="buttons">
								<div
									className={
										"b" + (this.state.b ? " pressed" : "")
									}
								>
									B
								</div>
								<div
									className={
										"a" + (this.state.a ? " pressed" : "")
									}
								>
									A
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
