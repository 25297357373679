import React from "react";

import "./WebOpener.css";

const WebOpener = ({ url, name, endLoad }) => (
	<>
		<iframe
			className="w-100 h-100"
			title={name}
			src={url}
			onLoad={endLoad}
		/>
	</>
);

export default WebOpener;
