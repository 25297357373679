export const calculateMagnetPosition = (
	x,
	y,
	margin = 10,
	cont = document.body
) => {
	let box = cont.getBoundingClientRect();
	let width = box.width;
	let height = box.height;

	if (x < 0 && x > -margin * 2) {
		return {
			x: 0,
			y: 0,
			w: width / 2,
			h: height,
			type: "left",
		};
	} else if (x > width - margin) {
		return {
			x: width / 2,
			y: 0,
			w: width / 2,
			h: height,
			type: "right",
		};
	} else if (y < 0 && y > -margin * 2) {
		return {
			x: 0,
			y: 0,
			w: width,
			h: height / (y < 0 && y > -(margin / 2) ? 2 : 1),
			type: y > -(margin / 2) ? "top" : "full",
		};
	}

	return false;
};
