import React, { Component } from "react";
import { motion } from "framer-motion";
import { DragDropContext } from "react-beautiful-dnd";
import { Droppable } from "react-beautiful-dnd";

import "./Dock.css";
import Icon from "../Icon/Icon";
import DockFolder from "./components/DockFolder/DockFolder";
import DraggableIcon from "../Icon/DraggableIcon";
import genUUID from "../../includes/UUID";

class Dock extends Component {
	constructor(props) {
		super(props);
		this.state = {
			curFolder: false,
		};

		this.isRunning = this.isRunning.bind(this);
		this.getAlias = this.getAlias.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.anims = {
			hide: {
				bottom: "-300px",
			},
			show: {
				bottom: "0px",
				transition: {
					duration: 2,
					ease: "easeOut",
				},
			},
		};
	}

	componentDidMount() {
		window.addEventListener("click", this.handleClose);
	}

	componentWillUnmount() {
		window.removeEventListener("click", this.handleClose);
	}

	handleClose(e) {
		e.preventDefault();
		e.stopPropagation();
		if (
			this.state.curFolder?.name &&
			!e.target.classList.contains("folder-cont")
		)
			this.setState({ curFolder: false });
	}

	isRunning(app) {
		let opened = this.props.opened;

		for (let x in opened)
			if (opened[x].abs_path === app.abs_path) return true;
		return false;
	}

	getAlias = (path) => this.props.disk.read(path);

	render() {
		return (
			<motion.div
				className={
					"Dock" + (this.state.curFolder ? " folder-open" : "")
				}
				variants={this.anims}
				initial="hide"
				animate="show"
			>
				<div className="dock-cont">
					<DockItems
						user={this.props.disk.user()}
						launch={this.props.launch}
						items={this.props.items}
						getAlias={this.getAlias}
						curFolder={this.state.curFolder}
						setCurFolder={(folder) =>
							this.setState({ curFolder: folder })
						}
						isRunning={this.isRunning}
						opened={this.props.opened}
					/>
				</div>
			</motion.div>
		);
	}
}

class DockItems extends Component {
	constructor(props) {
		super(props);
		let apps = [];
		let folders = [];
		props.items?.forEach((a) => {
			let item = a;
			if (item.type === "alias") {
				let alias = this.props.getAlias(a.path);
				if (alias) {
					item = alias;
					item.abs_path = a.path;
				}
			}
			item.UUID = genUUID();
			if (item.files || item.type === "folder") folders.push(item);
			else if (item) apps.push(item);
		});

		this.state = {
			apps: apps,
			folders: folders,
			lastUpdate: Date.now(),
		};

		this.trash = this.props.getAlias(this.props.user.absPath() + "/bin");
		this.explorer = this.props.getAlias("/apps/Explorer");

		this.onDragEnd = this.onDragEnd.bind(this);
		this.isInDock = this.isInDock.bind(this);
		this.reorder = this.reorder.bind(this);
		this.getElems = this.getElems.bind(this);
	}

	onDragEnd(result) {
		const { source, destination } = result;

		if (!destination) {
			return;
		}

		if (source.droppableId === destination.droppableId)
			this.reorder(result);
		else {
		}
	}

	getElems(destination) {
		let elems = false;
		if (destination.droppableId === "dock-apps") {
			elems = {
				type: "apps",
				elems: Array.from(this.state.apps),
			};
		} else if (destination.droppableId === "dock-folders") {
			elems = {
				type: "folders",
				elems: Array.from(this.state.folders),
			};
		}
		return elems;
	}

	reorder(result) {
		const { source, destination } = result;
		let res = this.getElems(destination);
		if (!res) return false;
		const [removed] = res.elems.splice(source.index - 1, 1);
		res.elems.splice(destination.index - 1, 0, removed);
		this.setState({
			apps: res.type === "apps" ? res.elems : this.state.apps,
			folders: res.type === "folders" ? res.elems : this.state.folders,
			lastUpdate: Date.now(),
		});
	}

	isInDock(app) {
		for (let x in this.state.apps)
			if (this.state.apps[x].abs_path === app.abs_path) return true;
		return false;
	}

	render() {
		return (
			<DragDropContext
				onDragEnd={this.onDragEnd}
				className="h-100"
				lastUpdate={this.state.lastUpdate}
			>
				<div>
					<Icon
						onClick={this.props.launch}
						isInDock={true}
						app={this.explorer}
						isRunning={this.props.isRunning(this.explorer)}
						{...this.explorer}
					/>
				</div>
				<div className="dock-apps">
					{this.state.apps?.length > 0 && (
						<Droppable
							droppableId="dock-apps"
							direction="horizontal"
						>
							{(provided) => (
								<>
									{this.state.apps.map((item, index) => {
										return (
											<div
												ref={provided.innerRef}
												index={index}
												key={item.UUID}
												{...provided.droppableProps}
											>
												<DraggableIcon
													onClick={this.props.launch}
													isInDock={true}
													{...item}
													app={item}
													index={index}
													isRunning={this.props.isRunning(
														item
													)}
												/>
											</div>
										);
									})}
									{provided.placeholder}
								</>
							)}
						</Droppable>
					)}
				</div>
				{
					<div className="opened-apps">
						{this.props.opened?.map((item, index) => {
							if (item.hidden || this.isInDock(item))
								return false;
							return (
								<Icon
									onClick={this.props.launch}
									isInDock={true}
									key={index}
									app={item}
									isRunning={this.props.isRunning(item)}
									{...item}
								/>
							);
						})}
					</div>
				}
				<div className="dock-folders">
					{this.state.folders?.length > 0 && (
						<Droppable
							droppableId="dock-folders"
							direction="horizontal"
						>
							{(provided) => (
								<>
									{this.state.folders.map((item, index) => {
										return (
											<div
												ref={provided.innerRef}
												{...provided.droppableProps}
												key={item.UUID}
											>
												<DockFolder
													launch={this.props.launch}
													curFolder={
														this.props.curFolder
													}
													isOpen={
														this.props.curFolder
															?.name === item.name
															? true
															: false
													}
													setCurFolder={
														this.props.setCurFolder
													}
													index={index}
													getAlias={
														this.props.getAlias
													}
													item={item}
												/>
											</div>
										);
									})}
									{provided.placeholder}
								</>
							)}
						</Droppable>
					)}
				</div>
				<div>
					<Icon
						onClick={this.props.launch}
						isInDock={true}
						app={this.trash}
						iconsFn={() => {
							if (this.trash.files?.length) return "full";
							return "empty";
						}}
						isRunning={this.props.isRunning(this.trash)}
						{...this.trash}
						path={this.trash.abs_path}
					/>
				</div>
			</DragDropContext>
		);
	}
}

export default Dock;
