export default class KeyManager {
	constructor(keys, sequence, onSequenceDone) {
		this.keys_cb = keys;
		this.pressed = {};
		this.lasts = [];
		this.max_lasts = 10;
		this.sequence = sequence;
		if (sequence?.length) this.max_lasts = sequence.length;
		this.onSequenceDone = onSequenceDone;
	}

	handlePress = (key) => {
		if (!this.pressed[key.code]) {
			for (let x in this.keys_cb)
				if (this.keys_cb[x].code === key.code)
					this.pressed[key.code] = this.keys_cb[x];
			if (this.pressed[key.code]?.callback)
				this.pressed[key.code].callback();
			this.lasts.push(key.code);
			if (this.lasts.length > this.max_lasts) this.lasts.shift();
			let check = this.checkSequence();
			if (check && this.onSequenceDone) this.onSequenceDone();
		}
	};

	checkSequence() {
		if (!this.sequence) return false;
		let lasts = this.lasts.join(".");
		let sequence = this.sequence.join(".");
		if (lasts === sequence) return true;
		return false;
	}

	handleRelease = (key) => {
		if (this.pressed[key.code]) {
			if (this.pressed[key.code].onKeyUp)
				this.pressed[key.code].onKeyUp();
			delete this.pressed[key.code];
		}
	};

	treatKeys = () => {
		for (let x in this.pressed)
			if (this.pressed[x].callback) this.pressed[x].callback();
	};

	isPressed = (code) => this.pressed[code] || false;
}
