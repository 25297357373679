import Gamebox from "./Gamebox";
import icon from "./icon.png";

const GameBoxApp = {
	name: "Gamebox",
	icon: icon,
	ext: "app",
	appName: "Gamebox",
	path: "Gamebox",
	datagrid: {
		w: 380,
		minW: 350,
		h: 625,
		minH: 600,
	},
	component: Gamebox,
};

export default GameBoxApp;
