import React, { useState } from "react";
import SwitchInput from "../../../../components/Inputs/Switch/SwitchInput";
import useTheme from "../../../../components/Theme/useTheme";
import icon from "./icon.png";
import { BlockPicker } from "react-color";
import "./Appearance.css";

function AppearanceSettings(props) {
	const disk = props.disk;
	let User = disk.user();
	const { theme, setTheme, color, setColor } = useTheme(
		User.appearance().theme
	);

	function switchTheme(value) {
		let cur = User.appearance();
		cur.theme = value;
		User.appearance(cur);
		setTheme(value);
	}

	function changeColor(value) {
		let cur = User.appearance();
		cur.color = value;
		User.appearance(cur);
		setColor(value);
	}

	return (
		<div className="d-flex flex-column gap-4">
			<div>
				<h4>Couleur</h4>
				<ColorPicker color={color.hex} onChange={changeColor} />
			</div>
			<div className="">
				<h4>Thème</h4>
				<SwitchInput
					checked={theme === "dark"}
					onChange={(e) =>
						switchTheme(e.target.checked ? "dark" : "light")
					}
					label={"Thème sombre"}
				/>
			</div>
		</div>
	);
}

const ColorPicker = (props) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className="d-flex gap-2">
			<div
				className="color-preview"
				onClick={() => setIsOpen(!isOpen)}
				style={{ backgroundColor: props.color }}
			></div>
			{isOpen && (
				<div onMouseLeave={() => setIsOpen(false)}>
					<BlockPicker
						triangle={"hide"}
						color={props.color}
						onChange={props.onChange}
					/>
				</div>
			)}
		</div>
	);
};

export const AppearanceSettingsApp = {
	icon: icon,
	name: "Apparence",
	component: AppearanceSettings,
};

export default AppearanceSettings;
