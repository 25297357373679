import React, { useState, useRef } from "react";
import Moment from "react-moment";
import { motion } from "framer-motion";

import { usePopper } from "react-popper";
// Font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPizzaSlice } from "@fortawesome/free-solid-svg-icons";

import "./TopBar.css";
import Locales from "../../Locales/Locales";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
function TopBar(props) {
	const users = props.disk.list("/users");

	const [activeMenu, setActiveMenu] = useState(false);

	const cont_ref = useRef(null);
	useOutsideAlerter(cont_ref, closeMenu);

	function closeMenu() {
		if (activeMenu) setActiveMenu(false);
	}

	const anims = {
		hide: {
			top: "-100px",
		},
		show: {
			top: "0px",
			transition: {
				duration: 2,
				ease: "easeOut",
			},
		},
	};

	return (
		<motion.div
			className="TopBar"
			ref={cont_ref}
			variants={anims}
			initial="hide"
			animate="show"
		>
			<div className="d-flex gap-2 align-items-center">
				<FontAwesomeIcon icon={faPizzaSlice} />
				<TopBarMenus
					app={props.active}
					items={props.active?.menu}
					active={activeMenu}
					setActive={setActiveMenu}
				/>
			</div>
			<div className="d-flex">
				<div className="item clock">
					<Moment
						interval={10000}
						withTitle
						titleFormat={Locales("Dates", "DAY_TITLE_FORMAT")}
						format={"HH:mm"}
					></Moment>
				</div>
				<UsersMenu
					user={props.user}
					story={props.story}
					users={users}
					setCurUser={props.setCurUser}
				/>
			</div>
		</motion.div>
	);
}

function TopBarMenus(props) {
	const { app, active, setActive, items } = props;

	const focus = (item) => {
		if (active.title !== item.title) setActive(item);
		else setActive(false);
	};

	if (!app) return false;
	if (!items || true)
		// disabled for now
		return <div>{app?.name}</div>;
	return (
		<div
			className="d-flex align-items-center"
			onClick={() => setActive(!active)}
		>
			{items.map((a, key) => (
				<Menu
					key={key}
					item={a}
					isFocused={active ? true : false}
					setIsFocused={focus}
					display={active?.title === a.title}
				/>
			))}
		</div>
	);
}

function Menu(props) {
	const { item, isFocused, setIsFocused } = props;
	const { display } = props;
	const [referenceElement, setReferenceElement] = useState(null);
	const [popperElement, setPopperElement] = useState(null);
	const [TM, setTM] = useState(false);

	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		placement: "bottom-start",
		modifiers: [
			{
				name: "offset", //offsets popper from the reference/button
				options: {
					offset: [-7, 0],
				},
			},
		],
	});

	const showPopper = (e) => {
		e.stopPropagation();
		resetTm();
		setIsFocused(item);
	};

	const resetTm = (new_tm) => {
		clearTimeout(TM);
		setTM(new_tm ? new_tm : false);
	};

	return (
		<div
			onClick={showPopper}
			onMouseEnter={(e) => {
				if (isFocused && !display) showPopper(e);
			}}
			className={`TopBarMenu ${display ? "active" : ""}`}
		>
			<div ref={setReferenceElement}>{item.title}</div>
			{display && (
				<div
					style={styles.popper}
					{...attributes.popper}
					ref={setPopperElement}
				>
					<MenuPopper item={item} />
				</div>
			)}
		</div>
	);
}

function MenuPopper(props) {
	const { item } = props;
	const childs = item.childs;

	if (!childs?.length) return <hr />;
	return (
		<div className="MenuPopper">
			{childs.map((a, key) => {
				if (a.sep) return <hr key={key} />;
				return <MenuItem key={key} item={a} />;
			})}
		</div>
	);
}

function MenuItem({ item }) {
	return <div className="MenuItem">{item.title}</div>;
}

function UsersMenu(props) {
	const [isOpen, setIsOpen] = useState(false);

	const anims = {
		hide: {
			opacity: 0,
			top: "50%",
			transition: {
				duration: 0.5,
				ease: "easeOut",
			},
		},
		show: {
			opacity: 1,
			top: "100%",
			transition: {
				duration: 0.5,
				ease: "easeOut",
			},
		},
	};

	function handleClose(e) {
		if (isOpen) setIsOpen(false);
	}

	React.useEffect(() => {
		window.addEventListener("click", handleClose);
		return () => {
			window.removeEventListener("click", handleClose);
		};
	});

	return (
		<div className="UsersMenu" onClick={() => setIsOpen(!isOpen)}>
			<a href="#curUser" className="cur-user-name">
				{props.user.name()}
			</a>
			{isOpen && (
				<motion.div
					className={"users-cont open"}
					variants={anims}
					initial="hide"
					animate="show"
				>
					{props.users?.map((a, key) => {
						if (
							a.hidden &&
							!(
								a.path === "unknown" &&
								props.story.step("konami")
							)
						)
							return false;
						return (
							<a
								href="#user"
								key={key}
								className={
									"user d-flex align-items-center" +
									(props.user.path === a.path
										? " active"
										: "")
								}
								onClick={() => props.setCurUser(a)}
							>
								<div
									className="user-image me-2"
									style={{
										backgroundImage: "url(" + a.image + ")",
									}}
								/>
								<div className="user-name">{a.name}</div>
							</a>
						);
					})}
					<hr />
					<a
						href="#disconnect"
						className="ms-1"
						onClick={() => props.setCurUser(false)}
					>
						Déconnexion
					</a>
				</motion.div>
			)}
		</div>
	);
}

export default TopBar;
