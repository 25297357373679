import Explorer from "../../applications/Explorer/Explorer";
import AppOpener from "./Openers/App/AppOpener";
import WebOpener from "./Openers/Web/WebOpener";

const Executer = (file) => {
	const exts = [
		{
			type: "folder",
			opener: Explorer,
		},
		{
			ext: "txt",
			name: "text",
			opener: null,
		},
		{
			ext: "web",
			name: "URL",
			opener: WebOpener,
		},
		{
			ext: "app",
			name: "Application",
			opener: AppOpener,
		},
	];

	for (let x in exts) {
		if (
			exts[x].ext === file.ext ||
			(exts[x].type && file.type && exts[x].type === file.type)
		) {
			let ret = {
				Opener: exts[x].opener,
				props: file,
			};
			return ret;
		}
	}
	return false;
};

export default Executer;
