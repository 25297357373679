import StorySteps from "../../story/StorySteps";
import { ICONS_PATH } from "../Paths";

import Shimon42 from "../Users/DefaultsUsers/Shimon42";
import UnknownUser from "../Users/DefaultsUsers/Unknown";
import { WallpapersList } from "../../components/Wallpaper/Wallpaper";
import { Apps, Games } from "../../applications/ApplicationsList";

const date = Date.now();
const DefaultRoot = {
	name: "Root",
	path: "/",
	type: "folder",
	files: [
		{
			name: "Applications",
			path: "apps",
			type: "folder",
			created: date,
			icon: ICONS_PATH + "AppFolder.png",
			files: [
				{
					hidden: true,
					name: ".",
					type: "alias",
					path: "/",
				},
				{
					name: "Fichiers",
					icon: ICONS_PATH + "home.png",
					ext: "app",
					path: "Explorer",
					appName: "Explorer",
					hidden: true,
					datagrid: {
						y: 20,
						w: 1280,
						h: 800,
						minW: 230,
					},
				},
				{
					name: "WebOs",
					icon: ICONS_PATH + "default.png",
					url: "//s-ferrara.fr?innerframe=true&" + Date.now(),
					path: "WebOs",
					ext: "web",
					type: "file",
					datagrid: {
						w: 1280,
						h: 800,
					},
				},
				...Apps,
				{
					name: "Jeux",
					path: "games",
					type: "folder",
					icon: ICONS_PATH + "GameFolder.png",
					files: [
						{
							hidden: true,
							name: ".",
							type: "alias",
							path: "/apps",
						},
						...Games,
					],
				},
			],
		},
		{
			name: "Users",
			path: "users",
			type: "folder",
			files: [
				{
					hidden: true,
					name: ".",
					type: "alias",
					path: "/users",
				},
				UnknownUser,
				Shimon42,
			],
		},
	],
	env: {
		wallpapers: WallpapersList,
	},
	story: StorySteps,
	version: "0.14.0",
};

export default DefaultRoot;
