import { ft_to_360 } from "../includes/convAngles";
import { calc_dist } from "../includes/point";

const PI = Math.PI;
export default class RayCasting {
	constructor(map, player) {
		this.map = map;
		this.player = player;
	}

	closest_grid_h = (angle) => {
		let ret = {
			x: null,
			y: null,
		};
		if (angle > PI && angle < 2 * PI)
			ret.y =
				Math.floor(this.player.posY / this.map.bloc_size) *
				this.map.bloc_size;
		else
			ret.y =
				Math.floor(this.player.posY / this.map.bloc_size) *
					this.map.bloc_size +
				this.map.bloc_size;
		ret.x = this.player.posX - (this.player.posY - ret.y) / Math.tan(angle);
		return ret;
	};

	closest_grid_v = (angle) => {
		let ret = {
			x: null,
			y: null,
		};
		if (angle > PI / 2 && angle < 2 * PI * 0.75)
			ret.x =
				Math.floor(this.player.posX / this.map.bloc_size) *
				this.map.bloc_size;
		else
			ret.x =
				Math.floor(this.player.posX / this.map.bloc_size) *
					this.map.bloc_size +
				this.map.bloc_size;
		ret.y = this.player.posY - (this.player.posX - ret.x) * Math.tan(angle);
		return ret;
	};

	closest_wall_h = (angle) => {
		let verif, is_wall;
		let d = {
			dist: null,
			hit: null,
			w_side_hit: null,
			from: null,
		};
		let offset = {
			x: 0,
			y: 0,
		};
		verif = angle > PI && angle < 2 * PI;
		is_wall = false;
		d.hit = this.closest_grid_h(angle);
		d.from = "h";
		offset.y = this.map.bloc_size * (verif ? -1 : 1);
		offset.x = (this.map.bloc_size / Math.tan(angle)) * (verif ? -1 : 1);
		while (!is_wall && d.hit.x < this.map.px_width && d.hit.x > 0) {
			is_wall =
				this.map.gridPos(d.hit.x, d.hit.y + 1) === 1 ||
				this.map.gridPos(d.hit.x, d.hit.y - 1) === 1;
			if (is_wall) break;
			d.hit.x += offset.x;
			d.hit.y += offset.y;
		}
		return d;
	};

	closest_wall_v = (angle) => {
		let verif, is_wall;
		let d = {
			dist: null,
			hit: null,
			w_side_hit: null,
			from: null,
		};
		let offset = {
			x: 0,
			y: 0,
		};
		verif = angle < 2 * PI * 0.75 && angle > PI / 2;
		is_wall = false;
		d.hit = this.closest_grid_v(angle);
		d.from = "v";
		offset.x = this.map.bloc_size * (verif ? -1 : 1);
		offset.y = this.map.bloc_size * Math.tan(angle) * (verif ? -1 : 1);
		while (!is_wall && d.hit.y < this.map.px_height && d.hit.y > 0) {
			is_wall =
				this.map.gridPos(d.hit.x - 1, d.hit.y) === 1 ||
				this.map.gridPos(d.hit.x + 1, d.hit.y) === 1;
			if (is_wall) break;
			d.hit.x += offset.x;
			d.hit.y += offset.y;
		}
		return d;
	};

	distToWall = (angle) => {
		let check_h, check_v;
		let dists = {
			x: 0,
			y: 0,
		};
		let bad_dist;
		let wall = {};
		angle = ft_to_360(angle);
		check_h = this.closest_wall_h(angle);
		check_v = this.closest_wall_v(angle);
		dists.h = calc_dist(this.player.getPos(), check_h.hit);
		dists.v = calc_dist(this.player.getPos(), check_v.hit);
		if (dists.h < dists.v) {
			bad_dist = dists.h;
			wall.detected = "h";
			wall.w_side_hit = this.map.getWallSide(angle, "h");
			wall.hit = check_h.hit;
		} else {
			wall.detected = "v";
			bad_dist = dists.v;
			wall.w_side_hit = this.map.getWallSide(angle, "v");
			wall.hit = check_v.hit;
		}
		wall.dist = bad_dist;
		return wall;
	};
}
