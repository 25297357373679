import SecureStorage from "secure-web-storage";
import CryptoJS from "crypto-js";
import DefaultRoot from "./DefaultRoot";
import UserController from "../Users/UserController";

class Storage {
	#SECRET_KEY = "202fdaef-7be6-43b1-ac8c-e3c9cc12306d";
	#Disk = false;
	#User = false;

	constructor(DiskImage, secretKey, forceReload) {
		let that = this;
		this.#SECRET_KEY = secretKey || this.#SECRET_KEY;
		this.forceReload = forceReload;
		const SecStorage = new SecureStorage(localStorage, {
			hash: function hash(key) {
				key = CryptoJS.SHA256(key, that.#SECRET_KEY);

				return key.toString();
			},
			encrypt: function encrypt(data) {
				data = CryptoJS.AES.encrypt(data, that.#SECRET_KEY);

				data = data.toString();

				return data;
			},
			decrypt: function decrypt(data) {
				data = CryptoJS.AES.decrypt(data, that.#SECRET_KEY);

				data = data.toString(CryptoJS.enc.Utf8);

				return data;
			},
		});

		let disk = SecStorage.getItem("Disk");

		if (!disk || DiskImage || disk.version !== DefaultRoot.version) {
			let disk_img = DiskImage ? DiskImage : DefaultRoot;
			disk_img.files = this.#indexFiles(disk_img, "/");
			SecStorage.setItem("Disk", disk_img);

			if (disk && disk?.version !== DefaultRoot.version) {
				window.location.replace("/?boot=Updating OS");
			}
		}
		this.#Disk = SecStorage;
	}

	user = (user) => {
		if (typeof user !== "undefined") {
			if (!user) {
				this.#User = false;
				return this.#User;
			}
			let temp = this.read("/users/" + user.path);
			if (temp)
				this.#User = new UserController({
					...user,
					folder: temp,
					storage: this,
				});
		}
		return this.#User;
	};

	#indexFiles = (folder, cur_path) => {
		let files = folder.files;
		for (let x in files) {
			// console.log("Scan File path:[%s] for [%s]", cur_path, files[x].path)
			if (files[x].type === "folder") {
				files[x].abs_path =
					cur_path + (cur_path !== "/" ? "/" : "") + files[x].path;
				files[x].files = this.#indexFiles(files[x], files[x].abs_path);
			} else {
				// console.log("Check File [%s/%s]", cur_path, files[x].path)
				if (files[x].type === "alias")
					files[x].abs_path = files[x].path;
				else
					files[x].abs_path =
						cur_path +
						(cur_path !== "/" ? "/" : "") +
						files[x].path;
				// console.log("file", files[x], cur_path)
			}
		}
		return files;
	};

	#scanFolder = (folder, cur_path, path) => {
		// console.log("Scan Folder cur:[%s] path:[%s]", cur_path, path, folder)
		if (cur_path === path && !folder.files?.length) return folder;
		if (!folder.files?.length) return false;
		let files = folder.files;
		for (let x in files) {
			// console.log("Scan File path:[%s] for [%s]", cur_path, files[x].path)
			if (files[x].type === "folder") {
				if (cur_path === path) {
					let ret_folder = folder;
					ret_folder.abs_path = cur_path;
					return ret_folder;
				}
				let check = this.#scanFolder(
					files[x],
					cur_path + (cur_path !== "/" ? "/" : "") + files[x].path,
					path
				);
				if (check) {
					return check;
				}
			} else {
				// console.log("Check File [%s/%s] vs [%s]", cur_path, files[x].path, path)
				if (cur_path + "/" + files[x].path === path) {
					let file = files[x];
					// console.log("file", file, path)
					return file;
				}
				if (cur_path === path) {
					let ret_folder = folder;
					// console.log("folder", ret_folder, path)
					return ret_folder;
				}
			}
		}
		return false;
	};

	getAppStorage = (app) => {
		let disk = this.#Disk.getItem("Disk");
		let ret = this.#scanFolder(disk, "/", app.abs_path);

		return {
			appPath: app.abs_path,
			datas: ret.storage,
			save: (save) => {},
		};
	};

	#saveAppStorage = (app, storage) => {};

	#gotoPath = (path) => {
		let disk = this.#Disk.getItem("Disk");
		let ret = this.#scanFolder(disk, "/", path);

		return ret;
	};

	write = (path, item) => {
		let disk = this.#Disk.getItem("Disk");
		disk = this.#writeItem(disk, "/", path, item);
		this.#Disk.setItem("Disk", disk);
		// console.log("NEW DISK", disk)
		return disk;
	};

	#writeItem = (folder, cur_path, path, item) => {
		//  console.log("Scan Folder cur:[%s] path:[%s]", cur_path, path, folder)
		if (cur_path === path) {
			// console.log("SET FOLDER")
			folder = item;
			return folder;
		}
		if (!folder.files?.length) return false;
		let files = folder.files;
		let next_fold;

		if (cur_path === "/") next_fold = path.split("/")[1];
		else {
			next_fold = path.replace(cur_path, "").split("/")[1];
		}

		// console.log("NExTFOLD [%s]", next_fold)
		let next_fold_found = false;
		for (let x in files) {
			// console.log(files[x].path + " vs " + next_fold)
			if (
				!next_fold ||
				files[x].path === next_fold ||
				cur_path === path
			) {
				next_fold_found = true;
				// console.log("Scan File path:[%s/%s]", cur_path, files[x].path)
				if (files[x].type === "folder") {
					if (cur_path === path) {
						let ret_folder = folder;
						ret_folder = item;
						ret_folder.abs_path = cur_path;
						return ret_folder;
					}
					let check = this.#writeItem(
						files[x],
						cur_path +
							(cur_path !== "/" ? "/" : "") +
							files[x].path,
						path,
						item
					);
					files[x] = check;
					break;
				} else {
					// console.log("Check File [%s/%s] vs [%s]", cur_path, files[x].path, path)
					if (cur_path + "/" + files[x].path === path) {
						files[x] = item;
						let file = files[x];
						// console.log("file", file, path)
						return file;
					}
					if (cur_path === path) {
						folder = item;
						let ret_folder = folder;
						// console.log("folder", ret_folder, path)
						return ret_folder;
					}
				}
			}
		}
		if (!next_fold_found && path === cur_path + next_fold) files.push(item);
		folder.files = files;
		return folder;
	};

	read = (path) => {
		let ret = this.#gotoPath(path);
		return ret;
	};

	list = (path) => {
		let ret = this.#gotoPath(path);
		if (ret) return ret.files;
		return ret;
	};
}

export default Storage;
