export default function genUUID() {
	// Public Domain/MIT
	let d = new Date().getTime();
	if (
		typeof performance !== "undefined" &&
		typeof performance.now === "function"
	) {
		d += performance.now(); //use high-precision timer if available
	}
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
		/[xy]/g,
		function (c) {
			let r = (d + Math.random() * 16) % 16 | 0;
			d = Math.floor(d / 16);
			let first_mask = r & 0x3;
			let last_mask = 0x8;
			return (c === "x" ? r : first_mask | last_mask).toString(16);
		}
	);
}
