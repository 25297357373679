import { PROJECTS_URL } from "../../System/Paths";
import icon from "./icon.png";

const SnakeApp = {
	name: "Snake",
	icon: icon,
	url: PROJECTS_URL + "snake",
	ext: "web",
	path: "Snake",
	datagrid: {
		w: 830,
		h: 690,
		maxW: 830,
		maxH: 690,
		isResizable: false,
	},
};

export default SnakeApp;
