import { PROJECTS_URL } from "../../System/Paths";
import icon from "./icon.png";

const PongApp = {
	name: "Pong",
	icon: icon,
	url: PROJECTS_URL + "pong",
	ext: "web",
	path: "Pong",
	datagrid: {
		w: 1030,
		h: 710,
	},
};

export default PongApp;
