import icon from "./icon.png";
import Settings from "./Settings";

const SettingsApp = {
	name: "Réglages",
	icon: icon,
	ext: "app",
	appName: "Settings",
	path: "Settings",
	datagrid: {
		w: 650,
		minW: 650,
		minH: 350,
		h: 350,
	},
	unique: true,
	history: true,
	component: Settings,
};

export default SettingsApp;
