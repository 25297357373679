import React, { useState } from "react";
import { motion } from "framer-motion";
// Font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import Icon from "../../../Icon/Icon";
import "./DockFolder.css";
import DraggableIcon from "../../../Icon/DraggableIcon";

function DockFolder({
	isOpen,
	item,
	index,
	setCurFolder,
	isInFolder,
	curFolder,
	onClick,
	launch,
	getAlias,
}) {
	const [innerFolder, setInnerFolder] = useState(isInFolder ? item : false);
	const anims = {
		hide: {
			bottom: "15px",
			opacity: 0,
			height: "0px",
			width: "0px",
		},
		show: {
			bottom: "60px",
			height: "auto",
			width: "auto",
			display: "flex",
			opacity: 1,
			transition: {
				duration: 0.5,
				ease: "easeOut",
			},
		},
	};

	React.useEffect(() => {
		if (!curFolder && innerFolder) setInnerFolder(false);
	}, [innerFolder, setInnerFolder, curFolder]);

	function handleClick(e) {
		e.preventDefault();
		e.stopPropagation();
		if (!isInFolder) {
			if (curFolder?.name !== item.name)
				setCurFolder({ name: item.name });
			else {
				setCurFolder(false);
				setInnerFolder(false);
			}
		}
	}

	return (
		<div
			className={
				"DockFolder" +
				(isOpen ? " open" : "") +
				(isInFolder ? " in-folder" : "")
			}
			onClick={handleClick}
		>
			<DraggableIcon
				isInFolder={isInFolder}
				isInDock={!isInFolder ? true : false}
				app={item}
				index={index}
				{...item}
			/>
			<motion.div
				variants={anims}
				initial="hide"
				animate={isOpen ? "show" : "hide"}
				className="folder-cont"
			>
				<Folder
					onClick={onClick}
					name={!innerFolder ? item.name : innerFolder.name}
					root={item}
					files={!innerFolder ? item.files : innerFolder.files}
					getAlias={getAlias}
					launch={launch}
					cd={setInnerFolder}
				/>
			</motion.div>
		</div>
	);
}

function Folder({ files, name, launch, cd, getAlias, root }) {
	if (!files?.length) return false;

	return (
		<>
			<div className="mb-3 d-flex justify-content-between align-items-center w-100">
				{files[0].name === "." &&
				root.files[0]?.path !== files[0].path ? (
					<div
						className="back-button"
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
							let item = getAlias(files[0].path);
							cd(item);
						}}
					>
						<FontAwesomeIcon icon={faChevronLeft} />
					</div>
				) : (
					<div></div>
				)}
				<div className="px-2">{name}</div>
				<div></div>
			</div>
			<div className="folder-inner-cont">
				{files
					?.sort((a, b) => (a.name < b.name ? -1 : 1))
					.map((a, index) => {
						let item = a;
						if (item.hidden) return false;
						if (a.type === "alias") {
							item = getAlias(a.path);
							item.name = a.name;
						}
						if (item.files)
							return (
								<Icon
									key={index}
									onClick={(app, e) => {
										e.stopPropagation();
										e.preventDefault();
										cd(item);
									}}
									isInFolder={true}
									isAlias={a.type === "alias"}
									item={item}
									{...item}
								/>
							);
						return (
							<Icon
								key={index}
								onClick={launch}
								isInFolder={true}
								app={item}
								{...item}
							/>
						);
					})}
			</div>
		</>
	);
}

export default DockFolder;
