export const Locales_en_EN = {
	Code: "en-EN",
	CodeMin: "en",

	Server: {
		CONNECTING: "Connecting to the server",
		PING_ERROR: "Error connecting to server",
		TIMEOUT: "The server took too long to respond",
		RETRY_LATER: "Please try again later, thank you",
	},

	Desktop: {
		LOAD_ERROR: "An error occurred while loading the Desktop",
		ERROR_404: "Error 404",
		PAGE_NOT_FOUND: "The page you are looking for does not exist",
	},

	Commons: {
		WELCOME: "Welcome",
		LOADING: "Loading",
	},

	Dates: {
		DAY_TITLE_FORMAT: "D dddd MMMM YYYY",
	},
};
