import React, { useState } from "react";
import ExplorerFile from "./components/File/ExplorerFile";
import ExplorerFolder from "./components/Folder/ExplorerFolder";

import "./Explorer.css";

function Explorer(props) {
	const storage = props.disk;
	// const appStorage = props.storage;
	let cur_path = props.abs_path ? props.abs_path : storage.user().absPath();
	const folder = storage.read(cur_path);
	const [curFolder, setCurFolder] = useState(folder);

	React.useEffect(() => {
		if (!props.isLoaded) {
			props.endLoad();
			props.setTitle(curFolder.name);
		}
	});

	const changeFolder = (new_folder) => {
		if (new_folder === "/") {
			setCurFolder(storage.read("/"));
			props.setTitle("Root");
		} else {
			setCurFolder(new_folder);
			props.setTitle(new_folder.name);
		}
	};

	const getAlias = (path) => storage.read(path);

	return (
		<div className="Explorer">
			{props.menu !== false && (
				<ExplorerMenu
					disk={props.disk}
					setCurFolder={changeFolder}
					root={folder}
				/>
			)}
			<div className="explorer-cont">
				{curFolder.files?.length >= 0 &&
					curFolder.files.map((a, index) => {
						let file = a;
						if (file.hidden) return false;
						if (a.type === "alias") {
							file = getAlias(a.path);
							file.name = a.name;
						}
						return file.type === "folder" ? (
							<ExplorerFolder
								key={index}
								folder={file}
								isAlias={a.type === "alias"}
								onClick={() => changeFolder(file)}
							/>
						) : (
							<ExplorerFile
								key={index}
								file={file}
								isAlias={a.type === "alias"}
								onClick={() => {
									props.launch(file);
								}}
							/>
						);
					})}
			</div>
		</div>
	);
}

function ExplorerMenu({ setCurFolder, disk }) {
	const folder = disk.read("/");

	return (
		<div className="ExplorerMenu">
			{/* <div
				className="item"
				onClick={() => setCurFolder("/")}
			>
				Root
			</div> */}
			<div
				className="item"
				onClick={() => setCurFolder(disk.read(disk.user().abs_path))}
			>
				Home
			</div>
			{folder.files?.map((item, index) => {
				if (item.hidden) return false;
				return (
					<div
						className="item"
						key={index}
						onClick={() => setCurFolder(item)}
					>
						{item.name}
					</div>
				);
			})}
		</div>
	);
}

export default Explorer;
