import { PROJECTS_URL } from "../../System/Paths";
import icon from "./icon.png";

const ShadesApp = {
	name: "Shades",
	icon: icon,
	url: PROJECTS_URL + "shades",
	ext: "web",
	path: "Shades",
	datagrid: {
		w: 1920,
		h: 1080,
	},
};

export default ShadesApp;
