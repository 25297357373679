const DefaultDock = [
	{
		name: "Psykala",
		type: "alias",
		path: "/apps/Psykala",
	},
	{
		name: "Shades",
		type: "alias",
		path: "/apps/Shades",
	},
	{
		name: "Notes",
		type: "alias",
		path: "/apps/Notes",
	},
	// {
	// 	name: "Musique",
	// 	type: "alias",
	// 	path: "/apps/Music",
	// },
	{
		name: "Réglages",
		type: "alias",
		path: "/apps/Settings",
	},
	{
		name: "Apps",
		type: "alias",
		path: "/apps",
	},
	{
		name: "Jeux",
		type: "alias",
		path: "/apps/games",
	},
	{
		name: "Documents",
		type: "alias",
		path: "/users/shimon42/documents",
	},
];

export default DefaultDock;
