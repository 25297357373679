import TrashApp from "../../../applications/Trash/TrashApp";
import DefaultDock from "../../../components/Dock/DefaultDock";
import { ICONS_PATH } from "../../Paths";
import avatar from "../assets/avatar.jpg";

const date = Date.now();
const Shimon42 = {
	name: "Siméon",
	path: "shimon42",
	type: "folder",
	image: avatar,
	appearance: {
		wallpaper: {
			id: 2,
			file: null,
		},
		theme: "light",
	},
	appsStorage: [],
	dock: {
		name: "Dock",
		path: "dock",
		type: "folder",
		hidden: true,
		files: DefaultDock,
	},
	files: [
		{
			hidden: true,
			name: ".",
			type: "alias",
			path: "/",
		},
		{
			...TrashApp,
			files: [],
		},
		{
			name: "Bureau",
			path: "desktop",
			type: "folder",
			created: date,
			files: [],
		},
		{
			name: "Documents",
			path: "documents",
			type: "folder",
			icon: ICONS_PATH + "DocumentsFolder.png",
			created: date,
			files: [
				{
					hidden: true,
					name: ".",
					type: "alias",
					path: "/home",
				},
				{
					name: "CV",
					url: "https://psykala.com",
					ext: "txt",
					type: "file",
					created: date,
				},
				{
					name: "Pictures",
					path: "pictures",
					type: "folder",
					created: date,
					files: [
						{
							hidden: true,
							name: ".",
							type: "alias",
							path: "/home/documents",
						},
						{
							name: "IMG_0420",
							ext: "jpeg",
							type: "file",
							created: date,
						},
						{
							name: "Apps Alias",
							type: "alias",
							path: "/apps",
							icon: ICONS_PATH + "DocumentsFolder.png",
						},
					],
				},
				{
					name: "Root Alias",
					type: "alias",
					path: "/",
				},
			],
		},
	],
};

export default Shimon42;
