import React, { Component } from "react";
import NoteEditor from "./components/NoteEditor/NoteEditor";
import genUUID from "../../includes/UUID";
import "./Notes.css";
import NotesList from "./components/NotesList/Noteslist";

export default class Notes extends Component {
	constructor(props) {
		super(props);

		let notes = props.storage?.datas?.Notes;
		if (!notes) notes = [];
		this.state = {
			curNote: null,
			Notes: notes.sort((a, b) => (a.modified < b.modified ? 1 : -1)),
		};
		this.changeNote = this.changeNote.bind(this);
		this.saveNote = this.saveNote.bind(this);
		this.updateNote = this.updateNote.bind(this);
		this.addNote = this.addNote.bind(this);
	}

	componentDidMount() {
		if (!this.props.isLoaded) this.props.endLoad();
		this.setState({
			curNote: this.state.Notes[0],
		});
	}

	changeNote(note) {
		this.setState(
			{
				curNote: null,
			},
			() => {
				this.setState({
					curNote: note,
				});
			}
		);
	}

	addNote() {
		let notes = this.state.Notes;
		let note = {
			id: genUUID(),
			text: "",
			modified: Date.now(),
		};
		notes.unshift(note);
		this.setState(
			{
				Notes: notes,
			},
			() => {
				this.changeNote(note);
			}
		);
	}

	updateNote(note, callback) {
		let notes = this.state.Notes;
		let index = notes.findIndex((a) => a.id === note.id);

		if (index !== -1) {
			notes[index] = note;
			notes[index].modified = Date.now();
		} else notes.push(note);
		if (notes[0]?.id !== note.id)
			notes = notes.sort((a, b) => (a.modified < b.modified ? 1 : -1));
		this.setState(
			{
				Notes: notes,
			},
			callback
		);
	}

	saveNote(note) {
		if (!this.props.storage) return false;
		//let storage = this.props.storage.datas;
	}

	render() {
		return (
			<div className="Notes">
				<div className="side-panel">
					<NotesList
						handleClick={this.changeNote}
						notes={this.state.Notes}
						storyNotes={this.props.story.getNotes()}
						curNote={this.state.curNote}
					/>
					<div className="add-note" onClick={this.addNote}>
						<div>Ajouter une note</div>
						<div className="add-btn">+</div>
					</div>
				</div>
				<div className="note-edit-cont">
					{this.state.curNote ? (
						<NoteEditor
							updateNote={this.updateNote}
							note={this.state.curNote}
							saveNote={this.saveNote}
						/>
					) : (
						"Sélectionnez une note ou créez en une"
					)}
				</div>
			</div>
		);
	}
}
