import React from "react";
import removeMd from "remove-markdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import "./NotesList.css";

function NotesList({ notes, storyNotes, handleClick, curNote }) {
	function getFirstLine(note) {
		let lines = removeMd(note.text).split(/\n|<br>/);

		for (let x in lines) if (lines[x].length) return lines[x];
		return "Note sans titre";
	}

	function genNote(note, index) {
		let isActive = note.id === curNote?.id;
		if (note.hidden) return false;
		return (
			<div
				className={"note" + (isActive ? " active" : "")}
				key={index}
				onClick={() => handleClick(note)}
			>
				<div className="note-title">{getFirstLine(note)}</div>
				{note.locked && (
					<FontAwesomeIcon className="ms-1" icon={faLock} />
				)}
			</div>
		);
	}

	let disp_notes = notes.concat(storyNotes);

	if (!disp_notes) return "Aucune note";
	return (
		<div className="notes-list">
			{disp_notes?.map((note, index) => genNote(note, index))}
		</div>
	);
}

export default NotesList;
