import rgbToHex from "../includes/rgbToHex";

export default class Map {
	constructor(map, ctx, callack) {
		this.width = 0;
		this.height = 0;
		this.px_width = 0;
		this.px_height = 0;
		this.bloc_size = 64;
		this.scale = 0.5;
		this.callack = callack;
		this.grid = [];
		this.p_angle = map.p_angle;
		this.p_spawn = null;
		this.img = null;
		this.map = map;
		this.textures = {
			w_n: {
				isColor: true,
				value: map.NO,
			},
			w_e: {
				isColor: true,
				value: map.EA,
			},
			w_s: {
				isColor: true,
				value: map.SO,
			},
			w_w: {
				isColor: true,
				value: map.WE,
			},
			floor: {
				isColor: true,
				walue: map.floor,
			},
			sky: {
				isColor: true,
				walue: map.sky,
			},
		};
		this.load(map);
	}

	load = (map) => {
		let map_img = new Image();
		map_img.src = map.file;
		map_img.onload = () => {
			this.grid = this.parse(map, map_img);
			this.img = map_img;
			this.width = map_img.width;
			this.height = map_img.height;
			this.px_width = this.width * this.bloc_size;
			this.px_height = this.height * this.bloc_size;
			document.body.removeChild(map_img);
			if (this.callack) this.callack();
		};
		document.body.appendChild(map_img);
	};

	parse = (map, map_img) => {
		let grid = [];
		let canvas = document.createElement("canvas");
		let ctx = canvas.getContext("2d");
		ctx.drawImage(map_img, 0, 0);
		for (let y = 0; y < map_img.height; y++) {
			let row;
			grid.push([]);
			row = grid[grid.length - 1];
			for (let x = 0; x < map_img.width; x++) {
				let p = ctx.getImageData(x, y, 1, 1).data;
				let hex =
					"#" + ("000000" + rgbToHex(p[0], p[1], p[2])).slice(-6);
				if (hex === map.wall) row.push(1);
				else if (hex === map.p_spawn) {
					this.p_spawn = {
						x,
						y,
					};
					row.push(2);
				} else if (hex === map.empty) row.push(0);
				else if (map?.props) {
					let found = false;
					for (let z in map.props) {
						if (hex === map.props[z].color) {
							row.push(map.props[z].id);
							found = true;
							break;
						}
					}
					if (!found) row.push(0);
				} else {
					row.push(0);
				}
			}
		}
		return grid;
	};

	getWallSide = (angle, closest) => {
		const PI = Math.PI;
		if ((angle >= 0) & (angle < PI)) {
			if (closest === "h") return "n";
			if (angle >= 0 && angle < PI / 2.0) return "w";
			else return "e";
		} else {
			if (closest === "h") return "s";
			if (angle >= PI && angle < 2.0 * PI * 0.75) return "e";
			else return "w";
		}
	};

	getWallTexture = (side) => {
		if (side === "n") return this.textures.w_n;
		if (side === "e") return this.textures.w_e;
		if (side === "s") return this.textures.w_s;
		if (side === "w") return this.textures.w_w;
	};

	getMapColor = (val) => {
		if (val === 0) return "#FFFFFF";
		else if (val === 1) return "#000000";
		else if (val === 2) return this.map.p_spawn;
		else if (this.map.props) {
			for (let z in this.map.props)
				if (val === this.map.props[z].id)
					return this.map.props[z].color;
		}
		return "#FF00FF";
	};

	gridVal = (x, y) => {
		let val = false;
		if (y < this.grid.length && x < this.grid[y].length)
			val = this.grid[y][x];
		return val;
	};

	gridPos = (px_x, px_y) => {
		let val = false;
		let x = Math.floor(px_x / this.bloc_size);
		let y = Math.floor(px_y / this.bloc_size);

		if (y < this.height && x < this.grid[y].length)
			if (typeof this.grid[y][x] !== "undefined") val = this.grid[y][x];
		return val;
	};
}
