import full from "./icon_full.png";
import empty from "./icon_empty.png";

const TrashApp = {
	name: "Corbeille",
	path: "bin",
	type: "folder",
	icons: {
		full: full,
		empty: empty,
	},
	hidden: true,
	childProps: {
		menu: false,
		path: "/bin",
	},
	files: [],
};

export default TrashApp;
