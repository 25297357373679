import Notes from "./Notes";

import icon from "./icon.png";

const NotesAppMenu = [
	{
		title: "Notes",
		childs: [
			{
				title: "A propos",
				cmd: "about",
			},
		],
	},
	{
		title: "Fichier",
		childs: [
			{
				title: "Nouvelle note",
				cmd: "add",
			},
		],
	},
	{
		title: "Edition",
		childs: [
			{
				title: "Copier",
				cmd: "copy",
			},
			{
				title: "Coller",
				cmd: "paste",
			},
		],
	},
];

const AboutNotes = (props) => {
	return (
		<div>
			<img alt={"Notes application icon"} src={NotesApp.icon} />
			<div>Version {NotesApp.version}</div>
		</div>
	);
};

const NotesApp = {
	name: "Notes",
	about: AboutNotes,
	ext: "app",
	path: "Notes",
	appName: "Notes",
	version: "0.2",
	icon: icon,
	unique: true,
	component: Notes,
	datagrid: {
		w: 800,
		h: 600,
	},
	storage: {
		Notes: [
			{
				text: "# Lorem ipsum dolor sit amet\n\n## Lorem ipsum dolor sit amet\n\n### Lorem ipsum dolor sit amet\n\n#### **Lorem ipsum dolor sit amet**\n\n##### **Lorem ipsum dolor sit amet**\n\n###### **Lorem ipsum dolor sit amet**\n\n**Lorem ipsum dolor sit amet**\nLorem ipsum dolor sit amet\n\n***\n\n*Consectetur adipiscing elit*. **Vestibulum urna orci,** ~~commodo id iaculis condimentum~~, pellentesque at massa. Etiam molestie ligula non purus porta consequat. In massa eros, auctor vitae porta eget, interdum vel sem. Integer ultricies justo sed magna semper finibus.\n\n1. Fusce vestibulum tellus ac ipsum eleifend,\n2. egestas euismod mauris ullamcorper.\n3. In semper porttitor enim, at imperdiet orci\n\n\n* Sed sed lacinia quam, eu iaculis velit.\n* Fusce gravida bibendum nisi ac tincidunt. \n* Quisque fringilla quam eu felis mattis, ac ultrices magna aliquet.\n    \n\n1. [x] Vestibulum laoreet consequat arcu\n2. [x] id convallis tellus interdum a.\n3. [x] Integer non luctus libero.\n4. [ ] Morbi finibus placerat magna sit amet finibus. \n5. [ ] Fusce vestibulum tellus ac ipsum\n    \n\n***\n\n> **Curabitur euismod** non urna nec commodo. Aliquam non ultrices felis. Etiam tincidunt dolor non rhoncus luctus. **Vivamus blandit egestas euismod**. Sed interdum nisi a orci tincidunt iaculis. Ut fringilla ex sit amet lorem iaculis, eget congue lorem interdum.\n> Ut egestas luctus fermentum. Mauris ac neque arcu. Suspendisse pharetra ligula volutpat tincidunt volutpat. Nunc auctor nisi eu porta egestas. Nulla eget massa eget eros fermentum tristique nec ut mauris. Proin bibendum a orci sed consectetur.\n>\n> ***\n\n| a | b | c | d | e |\n| --- | --- | --- | --- | --- |\n| a1 | b1 | c1 | d1 | e1 |\n| a2 | b2 | c2 | d2 | e2 |\n\n\n'",
			},
		],
	},
	menu: NotesAppMenu,
};

export default NotesApp;
