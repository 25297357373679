import { ft_inrad, ft_to_360 } from "../includes/convAngles";
import Camera from "./Camera";

export default class Player {
	constructor(context, map) {
		this.angle = map.p_angle;
		this.posX =
			map.p_spawn.x * map.bloc_size - map.bloc_size / 2 + map.bloc_size;
		this.posY =
			map.p_spawn.y * map.bloc_size - map.bloc_size / 2 + map.bloc_size;
		this.posZ = 0;
		this.speed = map.bloc_size * 0.2;
		this.speed_ratio = 1;
		this.rot_speed = ft_inrad(5);
		this.step = {
			x: this.posX + this.speed * Math.cos(this.angle) - this.posX,
			y: this.posY + this.speed * Math.sin(this.angle) - this.posY,
		};
		this.cam = new Camera(ft_inrad(60), context.canvas);
		this.divided = this.angle - this.cam.fov / 2;
		this.map = map;
		this.asMoved = true;
	}

	move = (dir) => {
		let new_x, new_y, check;
		new_x = this.posX + this.step.x * dir * this.speed_ratio;
		new_y = this.posY + this.step.y * dir * this.speed_ratio;

		check = this.map.gridPos(new_x, this.posY);
		if (check !== false && check !== 1) this.posX = new_x;
		check = this.map.gridPos(this.posX, new_y);
		if (check !== false && check !== 1) this.posY = new_y;
		this.asMoved = true;
	};

	sideMove(dir) {
		let check;
		let new_pos = {};
		new_pos.x =
			this.posX +
			this.speed *
				Math.cos(this.angle + ft_inrad(90) * dir) *
				this.speed_ratio;
		new_pos.y =
			this.posY +
			this.speed *
				Math.sin(this.angle + ft_inrad(90) * dir) *
				this.speed_ratio;
		check = this.map.gridPos(new_pos.x, this.posY);
		if (check !== false && check !== 1) this.posX = new_pos.x;
		check = this.map.gridPos(this.posX, new_pos.y);
		if (check !== false && check !== 1) this.posY = new_pos.y;
		this.asMoved = true;
	}

	rotate = (dir) => {
		this.angle = ft_to_360(
			this.angle + this.rot_speed * this.speed_ratio * dir
		);
		this.step = {
			x: this.posX + this.speed * Math.cos(this.angle) - this.posX,
			y: this.posY + this.speed * Math.sin(this.angle) - this.posY,
		};
		this.divided = this.angle - this.cam.fov / 2;
		this.asMoved = true;
	};

	getPos = () => ({ x: this.posX, y: this.posY });

	resetMove = (force) => {
		this.asMoved = force || false;
	};
}
