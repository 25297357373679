import React, { useState } from "react";
import { AppearanceSettingsApp } from "./Components/Appearance/Appearance";
import { WallPaperSettingsApp } from "./Components/Wallpaper/WallPaperSettings";
import "./Settings.css";

const SettingsApps = [AppearanceSettingsApp, WallPaperSettingsApp];

function Settings(props) {
	const [active, setActive] = useState(false);

	React.useEffect(() => {
		if (!props.isLoaded) {
			// props.history.push(() => {
			// 	props.setTitle(props.appProps.name)
			// 	setActive(false)
			// })
			props.endLoad();
		}
	}, [setActive, props]);

	function openChild(child) {
		props.history.push(() => {
			props.setTitle(active?.name ? active.name : props.appProps.name);
			setActive(active ? active : false);
		});
		props.setTitle(child.name);
		setActive(child);
	}

	return (
		<div className="Settings">
			{active ? (
				<div className="active-cont">
					<active.component disk={props.disk} />
				</div>
			) : (
				<div className="d-flex gap-4 flex-wrap align-items-start">
					{SettingsApps.map((a, key) => {
						return (
							<SettingsApp
								{...a}
								key={key}
								setActive={() => openChild(a)}
							/>
						);
					})}
				</div>
			)}
		</div>
	);
}

function SettingsApp(props) {
	return (
		<div className="SettingsApp" onClick={props.setActive}>
			<div
				className="icon"
				style={{
					backgroundImage: "url(" + props.icon + ")",
				}}
			/>
			<div className="name">{props.name}</div>
		</div>
	);
}

export default Settings;
