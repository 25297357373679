import React, { Component } from "react";
import genUUID from "../../includes/UUID";
import Dock from "../Dock/Dock";
import TopBar from "../TopBar/TopBar";
import WindowsManager from "../WindowsManager/WindowsManager";
import "./Home.css";

class Home extends Component {
	constructor(props) {
		super(props);

		this.state = {
			ProcessList: [],
			ActiveWin: null,
		};
		this.WMCont = React.createRef();
	}

	componentDidUpdate() {
		// console.log(this.state)
	}

	handleLaunch(app) {
		if (app.type === "alias") {
			let found = this.props.disk.read(app.path);
			if (found) app = found;
		}
		let unique_check = this.getProcess(false, app.UUID);
		if (app.unique && unique_check) this.setActive(unique_check.process);
		else this.newProcess(app);
	}

	genDataGrid(datagrid) {
		let width = document.body.clientWidth;
		let height = this.WMCont.current.offsetHeight;

		let ret = {
			x: width / 2 - 250,
			y: height / 2 - 250,
			w: 500,
			h: 500,
			minH: 200,
			minW: 200,
			maxH: height,
			maxW: width,
			resizeHandles: ["s", "e", "se"],
		};

		Object.assign(ret, datagrid);

		if (datagrid?.w) {
			ret.w = datagrid.w < width ? datagrid.w : width;
			if (!datagrid.x) ret.x = width / 2 - datagrid.w / 2;
		}
		if (datagrid?.h) {
			ret.h = datagrid.h < height ? datagrid.h : height;
			if (!datagrid.y) ret.y = height / 2 - ret.h / 2;
		}
		return ret;
	}

	newProcess(app) {
		let new_process_list = this.state.ProcessList;
		let new_process = {};
		Object.assign(new_process, app);
		new_process.winId = genUUID();
		new_process.lastUpdate = Date.now();
		new_process.app = app;
		new_process.datagrid = this.genDataGrid(app.datagrid);
		new_process_list.push(new_process);
		new_process_list.sort((a, b) => (a.lastUpdate < b.lastUpdate ? -1 : 1));
		this.setState({
			ProcessList: new_process_list,
		});
	}

	getProcess(processId, appId) {
		let index = this.state.ProcessList.findIndex(
			(a) =>
				(processId && a.winId === processId) ||
				(appId && a.UUID === appId)
		);

		if (index !== -1)
			return {
				process: this.state.ProcessList[index],
				index,
			};
		return false;
	}

	processUpdate(updateList) {
		let new_process_list = [...this.state.ProcessList];
		updateList.forEach((update) => {
			let process = this.getProcess(update.winId);
			if (!process) return false;
			let item = new_process_list[process.index];
			new_process_list[process.index] = {
				...item,
				...update,
			};
		});
		this.setState({
			ProcessList: new_process_list,
			LastUpdate: Date.now(),
		});
	}

	updateProcess(processId, values) {
		let process = this.getProcess(processId);

		if (!process) return false;

		let new_process_list = [...this.state.ProcessList];
		let item = new_process_list[process.index];
		new_process_list[process.index] = {
			...item,
			...values,
		};
		this.setState({
			ProcessList: new_process_list,
			LastUpdate: Date.now(),
		});
	}

	closeProcess(processId) {
		let process = this.state.ProcessList;
		let index = this.state.ProcessList.findIndex(
			(a) => a.winId === processId
		);

		if (index === -1) return false;

		let deleted = process.splice(index, 1);
		let new_active =
			this.state.ActiveWin.winId === deleted[0].winId
				? process[0]
				: this.state.ActiveWin;

		if (new_active) new_active.lastUpdate = Date.now();
		this.setState({
			ProcessList: process,
			ActiveWin: new_active,
		});
	}

	setActive(win) {
		win.lastUpdate = Date.now();
		this.setState({
			ActiveWin: win,
		});
	}

	render() {
		let cur_user = this.props.disk.user();
		const dock = cur_user.getDock();
		return (
			<div className="Home">
				<TopBar
					disk={this.props.disk}
					story={this.props.story}
					user={this.props.user}
					setCurUser={this.props.setCurUser}
					active={this.state.ActiveWin}
				/>
				<WindowsManager
					story={this.props.story}
					disk={this.props.disk}
					contRef={this.WMCont}
					close={this.closeProcess.bind(this)}
					launch={this.handleLaunch.bind(this)}
					processUpdate={this.processUpdate.bind(this)}
					windows={this.state.ProcessList}
					activeWin={this.state.ActiveWin}
					setActive={this.setActive.bind(this)}
					lastUpdate={this.state.LastUpdate}
				/>
				<Dock
					disk={this.props.disk}
					items={dock?.files}
					launch={this.handleLaunch.bind(this)}
					opened={this.state.ProcessList}
				/>
				<audio
					src="assets/sounds/welcome.m4a"
					autoPlay={true}
					onEnded={(e) => {
						e.target.remove();
					}}
				/>
			</div>
		);
	}
}

export default Home;
