import React, { useState } from "react";
import { motion } from "framer-motion";
import "./LogScreen.css";
import User from "../../System/Users/User";

function LogScreen({ setCurUser, disk, story, tempUser, fullScreen }) {
	const [isInitied, setIsInitied] = useState(false);
	const [active, setActive] = useState(tempUser ? tempUser : false);

	const enable_fullscreen =
		typeof fullScreen !== "undefined" ? fullScreen : true;
	let verifFullScreen = 0;

	const anims = {
		hide: {
			opacity: 0,
			backdropFilter: "blur(0px)",
		},
		show: {
			opacity: 1,
			backdropFilter: "blur(5px)",
		},
	};

	const users = disk.list("/users");

	React.useEffect(() => {
		if (!isInitied) {
			setTimeout(() => {
				setIsInitied(true);
			}, 1500);
		}
	}, [setIsInitied, isInitied]);

	function requestFullScreen(force) {
		var docElm = document.documentElement;
		if (verifFullScreen === 0 || force) {
			if (docElm.requestFullscreen) {
				docElm.requestFullscreen();
			} else if (docElm.mozRequestFullScreen) {
				docElm.mozRequestFullScreen();
			} else if (docElm.webkitRequestFullScreen) {
				docElm.webkitRequestFullScreen();
			}
			verifFullScreen = 1;
		} else {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen();
			}
			verifFullScreen = 0;
		}
	}

	function handleClick(new_cur, pass) {
		if (
			window.location.href.indexOf("innerframe") === -1 &&
			enable_fullscreen
		)
			requestFullScreen(true);
		if (new_cur.pass) setActive(new_cur);
		else if (!new_cur.pass) setCurUser(new_cur);
	}

	return (
		<motion.div
			className={"LogScreen" + (isInitied ? " initied" : "")}
			variants={anims}
			initial="hide"
			animate="show"
		>
			<div className="users-list">
				{users.map((a, key) => {
					if (!active || active.path === a.path) {
						if (
							!a.hidden ||
							(a.path === "unknown" && story.step("konami"))
						) {
							return (
								<User
									isActive={active?.path === a.path}
									key={key}
									{...a}
									user={a}
									handleClick={() => handleClick(a, a.pass)}
									close={() => setActive(false)}
									setCurUser={setCurUser}
								/>
							);
						}
					}
					return false;
				})}
			</div>
		</motion.div>
	);
}

export default LogScreen;
