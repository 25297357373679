import React, { Component } from "react";

export default class Minimap extends Component {
	constructor(props) {
		super(props);
		this.ctx = null;
		this.canRef = React.createRef();
		this.draw = this.draw.bind(this);
		this.draw_elems = this.draw_elems.bind(this);
		this.draw_minimap_closest = this.draw_minimap_closest.bind(this);
		this.draw_player_map = this.draw_player_map.bind(this);
		this.width = 300;
		this.scale = 1;
		this.disp = {
			x: 0,
			y: 0,
		};

		this.draw = this.draw.bind(this);
		this.draw_elems = this.draw_elems.bind(this);
		this.draw_elem = this.draw_elem.bind(this);
		this.draw_minimap_closest = this.draw_minimap_closest.bind(this);
		this.draw_player_dir = this.draw_player_dir.bind(this);
		this.draw_player_map = this.draw_player_map.bind(this);
		this.map_scaled = this.map_scaled.bind(this);
	}

	componentDidMount() {
		this.ctx = this.canRef.current.getContext("2d");
		this.ctx.imageSmoothingEnabled = true;
		this.ctx.lineWidth = 1;
		this.canRef.current.width = this.width;
		this.canRef.current.height = this.width;
		if (this.props.map && this.ctx && this.ctx.canvas)
			this.draw(this.width);
	}

	componentDidUpdate() {
		if (this.props.map && this.ctx && this.ctx.canvas) {
			this.draw(this.width);
		}
	}

	draw_elems(disp_x, disp_y, scale) {
		let x, y;

		y = 0;
		while (y < this.props.map.height) {
			x = 0;
			while (x < this.props.map.width) {
				let val = this.props.map.gridVal(x, y);
				if (val !== false) {
					this.draw_elem(
						Math.floor(
							disp_x +
								(x - 1) * (this.props.map.bloc_size * scale)
						),
						Math.floor(
							disp_y +
								(y - 1) * (this.props.map.bloc_size * scale)
						),
						val
					);
				}
				x++;
			}
			y++;
		}
	}

	draw_elem(disp_x, disp_y, val) {
		let map = this.props.map;
		this.ctx.fillStyle = map.getMapColor(val);
		this.ctx.strokeStyle = "#222200";
		let size = map.bloc_size * this.scale + 1;
		this.ctx.fillRect(disp_x, disp_y, size, size);
		if (this.scale > 0.1) {
			this.ctx.strokeRect(disp_x, disp_y, size, size);
		}
	}

	map_scaled = (x, y) => {
		return {
			x: x * this.scale,
			y: y * this.scale,
		};
	};

	draw_minimap_closest(disp, angle, debug) {
		let p_pos, wall;
		let ctx = this.ctx;
		let map = this.props.map;

		p_pos = this.map_scaled(this.props.player.posX, this.props.player.posY);
		wall = this.props.rc.distToWall(angle, debug);
		ctx.beginPath();
		ctx.moveTo(disp.x + p_pos.x, disp.y + p_pos.y);
		ctx.lineTo(
			disp.x + p_pos.x + wall.dist * map.scale * Math.cos(angle),
			disp.y + p_pos.y + wall.dist * map.scale * Math.sin(angle)
		);
		ctx.closePath();
		ctx.stroke();
		ctx.strokeStyle = "#FF0000";
	}

	draw_player_dir(pos, angle) {
		let ctx = this.ctx;
		ctx.strokeStyle = "#FF0000";
		ctx.beginPath();
		ctx.moveTo(pos.x, pos.y);
		ctx.lineTo(
			pos.x + 100 * Math.cos(angle),
			pos.y + 100 * Math.sin(angle)
		);
		ctx.closePath();
		ctx.stroke();
	}

	draw_player_map(m_pos) {
		let pos = {};
		let player = this.props.player;

		// this.ctx.strokeStyle = "#FF00FF";
		// this.draw_minimap_closest(m_pos, ft_to_360(player.angle - player.cam.fov / 2), true);
		// this.ctx.strokeStyle = "#FF0000";
		// this.draw_minimap_closest(m_pos, player.angle);
		// this.ctx.strokeStyle = "#00FF00";
		// this.draw_minimap_closest(m_pos, player.angle + player.cam.fov / 2);
		// this.ctx.fillStyle = "#FFF000";
		pos = {
			x:
				m_pos.x +
				(player.posX - 1 * this.props.map.bloc_size) * this.scale,
			y:
				m_pos.y +
				(player.posY - 1 * this.props.map.bloc_size) * this.scale,
		};
		this.ctx.fillStyle = "#FF0000";
		this.ctx.beginPath();
		this.ctx.arc(
			pos.x,
			pos.y,
			this.props.map.bloc_size * 0.5 * this.scale,
			0,
			2 * Math.PI
		);
		this.ctx.fill();
		this.ctx.closePath();
		//this.draw_player_dir(pos, player.angle)
	}

	draw(width) {
		let map = this.props.map;
		let can = this.ctx.canvas;
		this.ctx.clearRect(0, 0, can.width, can.height);
		let x = 5;
		let y = 5;
		let scale = this.scale;
		if (map.px_height > map.px_width) scale = width / map.px_height;
		else scale = width / map.px_width;
		if (map.px_height * scale > can.height) {
			scale = (can.height - 2 * (100 * this.scale)) / map.px_height;
			x = (can.width - map.width * map.bloc_size * scale) / 2;
			y = (can.height - map.height * map.bloc_size * scale) / 2;
		}
		this.scale = scale;
		this.disp.x = Math.floor(x);
		this.disp.y = Math.floor(y);
		this.ctx.lineWidth = 1;
		this.draw_elems(x, y, scale);
		this.draw_player_map({ x, y });
	}

	render() {
		return (
			<>
				<canvas className="Minimap" ref={this.canRef} />
			</>
		);
	}
}
