import React, { Component } from "react";
import { MapConfig } from "./assets/map";
import KeyManager from "./srcs/KeyManager";
import Map from "./srcs/Map";
import Minimap from "./srcs/Minimap";
import Player from "./srcs/Player";
import RayCasting from "./srcs/RayCasting";
import menu from "./assets/menu.png";

export default class Cube3D extends Component {
	constructor(props) {
		super(props);

		this.state = {
			canvas: null,
			ctx: null,
			isPaused: false,
			menu: true,
			map: null,
			rc: null,
			player: null,
			showMap: false,
		};

		this.canRef = React.createRef();
		this.debugCanRef = React.createRef();
		this.loop = this.loop.bind(this);
		this.drawWalls = this.drawWalls.bind(this);
		this.drawCol = this.drawCol.bind(this);
		this.pixelPut = this.pixelPut.bind(this);
		this.pause = this.pause.bind(this);
		this.handlePress = this.handlePress.bind(this);
		this.handleRelease = this.handleRelease.bind(this);
		this.animationFrame = null;

		this.keyManager = new KeyManager([
			{
				code: "KeyW",
				callback: () => this.state.player.move(1),
			},
			{
				code: "KeyS",
				callback: () => this.state.player.move(-1),
			},
			{
				code: "ArrowUp",
				callback: () => this.state.player.move(1),
			},
			{
				code: "ArrowDown",
				callback: () => this.state.player.move(-1),
			},
			{
				code: "KeyA",
				callback: () => this.state.player.sideMove(-1),
			},
			{
				code: "KeyD",
				callback: () => this.state.player.sideMove(1),
			},
			{
				code: "ArrowLeft",
				callback: () => this.state.player.rotate(-1),
			},
			{
				code: "ArrowRight",
				callback: () => this.state.player.rotate(1),
			},
			{
				code: "Semicolon",
				callback: () =>
					!this.state.showMap
						? this.setState({ showMap: true })
						: false,
				onKeyUp: () => this.setState({ showMap: false }),
			},
			{
				code: "Enter",
				callback: () => {
					if (
						(this.state.menu || this.state.isPaused) &&
						this.props.isFocused
					) {
						this.state.player.resetMove(true);
						this.setState(
							{
								menu: false,
								isPaused: false,
							},
							() => {
								this.loop();
							}
						);
					}
				},
			},
		]);

		this.menuImage = new Image();
		this.menuImage.src = menu;
	}

	componentDidMount() {
		let canvas, ctx;

		canvas = this.canRef.current;
		ctx = canvas.getContext("2d");
		if (!ctx) throw new Error("Cube3D Error - can't get canvas context");
		let map = new Map(MapConfig, ctx, () => {
			let player = new Player(ctx, map);
			let rc = new RayCasting(map, player);
			this.setState(
				{
					canvas,
					ctx,
					player,
					map,
					rc,
				},
				() => {
					this.loop();
				}
			);
		});
		window.addEventListener("keydown", this.handlePress);
		window.addEventListener("keyup", this.handleRelease);
	}

	handlePress(e) {
		if (this.props.isFocused) this.keyManager.handlePress(e);
	}

	handleRelease(e) {
		if (this.props.isFocused) this.keyManager.handleRelease(e);
	}

	componentWillUnmount() {
		cancelAnimationFrame(this.animationFrame);
		window.removeEventListener("keydown", this.handlePress);
		window.removeEventListener("keyup", this.handleRelease);
	}

	menu() {
		let ctx = this.state.ctx;
		ctx.clearRect(0, 0, this.state.canvas.width, this.state.canvas.height);
		ctx.drawImage(
			this.menuImage,
			0,
			0,
			this.state.canvas.width,
			this.state.canvas.height
		);
	}

	pause() {
		let ctx = this.state.ctx;
		let width = this.state.canvas.width * 0.2;
		ctx.font = width + "px Arial";
		ctx.textAlign = "center";
		ctx.textBaseline = "middle";
		ctx.fillStyle = "#FF0000";
		ctx.strokeStyle = "#FFFFFF";
		ctx.lineWidth = 5;
		ctx.strokeText(
			"PAUSE",
			this.state.canvas.width / 2,
			this.state.canvas.height / 2
		);
		ctx.fillText(
			"PAUSE",
			this.state.canvas.width / 2,
			this.state.canvas.height / 2
		);
		this.setState({
			isPaused: true,
		});
	}

	loop() {
		if (this.state.menu) return this.menu();
		if (this.props.isFocused && !this.isPaused) this.keyManager.treatKeys();
		else return this.pause();
		if (this.state.player.asMoved) {
			let ctx = this.state.ctx;
			ctx.clearRect(
				0,
				0,
				this.state.canvas.width,
				this.state.canvas.height
			);
			this.drawWalls();
			this.state.player.resetMove();
		}
		this.animationFrame = requestAnimationFrame(this.loop);
	}

	drawWalls() {
		let col = 0;
		let wall = {};
		let w_hgt;
		let cur_angle;
		let camera = this.state.player.cam;
		// let mid_wall;

		while (col < this.state.canvas.width) {
			cur_angle = this.state.player.divided + camera.col_step * col;
			wall = this.state.rc.distToWall(cur_angle);
			//this.map.sprites->column[col] = wall.dist;
			wall.dist *= Math.cos(
				(cur_angle < this.state.player.angle ? -1 : 1) *
					(this.state.player.angle - cur_angle)
			);
			w_hgt = Math.floor(
				(this.state.map.bloc_size / wall.dist) * camera.proj_dist
			);
			// mid_wall = w_hgt / 2;
			//  if (w_hgt < this.state.canvas.height)
			//  	this.drawSky(col, this.state.player.cam.divided.y - mid_wall + this.state.player.posZ);
			this.drawCol(w_hgt, col, wall);
			// if (w_hgt < this.state.canvas.height)
			//  	this.drawSky(cur_angle,
			//  		floor(camera.divided.y + mid_wall + this.state.player.z - 1), col);
			col++;
		}
	}

	drawSky(col, start, end) {
		let y = start;
		let color = "#000000";
		while (y < end) {
			// color = get_sky_color(b, ratio, (col + left), y);
			this.pixelPut(col, y, color);
			y++;
		}
	}

	drawFloor() {}

	drawCol(w_height, cur_col, wall) {
		let i;
		let ratio = {};
		let texture;
		let texture_col;
		let mid_wall;

		mid_wall = Math.floor(this.state.canvas.height / 2 - w_height / 2 + 1);
		texture_col =
			wall.w_side_hit === "n" || wall.w_side_hit === "s"
				? wall.hit.x % this.state.map.bloc_size
				: wall.hit.y % this.state.map.bloc_size;
		texture = this.state.map.getWallTexture(wall.w_side_hit);
		ratio.y = texture.height / w_height;
		ratio.x = (texture_col + 1) * texture.ratio;
		i = mid_wall < 0 ? mid_wall * -1 : 0;
		while (i <= w_height) {
			texture_col = texture.isColor !== -1 ? texture.value : "#ffddee";
			//  texture_col = (texture.isColor != -1 ?
			//  	texture.isColor :
			//  	pixel_get(*texture, ratio.x, (i + 1) * ratio.y));
			if (mid_wall + i + this.state.player.posZ >= 0)
				this.pixelPut(
					cur_col,
					mid_wall + i + this.state.player.posZ,
					texture_col
				);
			if (mid_wall + i > this.state.canvas.height) break;
			i++;
		}
	}

	pixelPut(x, y, color) {
		let ctx = this.state.ctx;

		ctx.fillStyle = color;
		ctx.fillRect(x, y, 1, 1);
	}

	render() {
		return (
			<>
				<div className="screen-color"></div>
				<canvas ref={this.canRef} />
				{this.state.showMap && (
					<Minimap
						rc={this.state.rc}
						ctx={this.state.ctx}
						map={this.state.map}
						player={this.state.player}
						pos={this.state.player?.posX}
					/>
				)}
			</>
		);
	}
}
