import { useState } from "react";

function useTheme(over_theme) {
	const changeClass = (userTheme) => {
		let cont = document.querySelector("#root");
		cont.className = "";
		localStorage.setItem("theme", userTheme);
		cont.classList.add("theme-" + userTheme);
	};

	const applyColor = (color) => {
		let cont = document.querySelector("#theme-vars");
		if (color?.hex) {
			localStorage.setItem("color", JSON.stringify(color));
			let med = getMedianColor(color.hex);
			let font_color = med > 90 ? "black" : "white";
			cont.innerHTML = `* { 
				--color-primary: ${color.hex};
				--color-primary-font-color: ${font_color};
				--color-primary-rgb: ${color.rgb?.r}, ${color.rgb?.g}, ${color.rgb?.b};
			}`;
		}
	};

	const getTheme = () => {
		const themeString = localStorage.getItem("theme");
		changeClass(themeString);
		return themeString;
	};

	const getColor = () => {
		let colorString = JSON.parse(localStorage.getItem("color"));
		if (!colorString) colorString = { hex: "#3b83cc" };
		applyColor(colorString);
		return colorString;
	};

	const [theme, setTheme] = useState(getTheme());
	const [color, setColor] = useState(getColor());

	const saveTheme = (userTheme) => {
		changeClass(userTheme);
		return setTheme(userTheme);
	};

	const saveColor = (userColor) => {
		applyColor(userColor);
		return setColor(userColor);
	};

	return {
		setTheme: saveTheme,
		theme,

		setColor: saveColor,
		color,
	};
}

export const getMedianColor = (color) => {
	if (!color) return 0;
	color = color.replace("#", "").match(/[0-9A-Fa-f]{2}/g, 2);
	let total = 0;
	for (let x = 0; x < color.length; x++) {
		total += parseInt(color[x], 16);
	}

	return total / color.length;
};

export default useTheme;
