import React from "react";

import "./SwitchInput.css";

function SwitchInput(props) {
	return (
		<label
			className={
				"d-flex align-items-center" +
				(props.reverse ? " flex-row-reverse" : "") +
				(props.className ? " " + props.className : "")
			}
		>
			<input
				type="checkbox"
				className={"switch " + (props.reverse ? "ms-2" : "me-2")}
				checked={props.checked}
				onChange={(e) => {
					e.stopPropagation();
					props.onChange(e);
				}}
			/>
			<div>{props.label}</div>
		</label>
	);
}

export default SwitchInput;
