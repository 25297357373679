import React, { useState } from "react";
import icon from "./icon.png";

import "./WallPaperSettings.css";

function WallPaperSettings(props) {
	const disk = props.disk;
	let User = disk.user();
	const List = disk.read("/").env.wallpapers;
	const [active, setActive] = useState(
		getCurrent(User?.appearance()?.wallpaper.id)
	);

	function getCurrent(id) {
		for (let x in List) if (List[x].id === id) return List[x];
		return false;
	}

	function setCurrent(wp) {
		let cur = User.appearance();
		cur.wallpaper = wp;
		User.appearance(cur);
		setActive(wp);
	}

	return (
		<div className="WPSettings d-flex flex-column">
			<div className="d-flex top-cont">
				{active && (
					<div
						className="preview"
						style={{
							backgroundImage: "url(" + active.image + ")",
						}}
					/>
				)}
			</div>
			<div className="wp-list mt-4">
				{List.map((a, key) => {
					return (
						<WpPreview
							{...a}
							key={key}
							onClick={() => setCurrent(a)}
						/>
					);
				})}
			</div>
		</div>
	);
}

function WpPreview(props) {
	return (
		<div className="WpPreview" onClick={props.onClick}>
			<div
				className="image"
				style={{
					backgroundImage: "url(" + props.image + ")",
				}}
			/>
			<div className="name">{props.name}</div>
		</div>
	);
}

export const WallPaperSettingsApp = {
	icon: icon,
	name: "Wallpaper",
	component: WallPaperSettings,
};

export default WallPaperSettings;
