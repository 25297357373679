import React, { useState } from "react";
import CryptoJS from "crypto-js";
// Font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import "./User.css";
function User(props) {
	const [pass, setPass] = useState("");
	const [wrongPass, setWrongPass] = useState(false);
	const SECRET_KEY = "a497f506-3b75-440d-8d67-92f5f384df86";
	let timeout;

	function handleClick() {
		if (props.handleClick) return props.handleClick();
	}

	function checkPass() {
		let check = CryptoJS.SHA1(pass, SECRET_KEY).toString();

		if (check === props.pass) props.setCurUser(props.user, true);
		else {
			setWrongPass(true);
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				setWrongPass(false);
			}, 600);
		}
	}

	return (
		<div
			className={
				"User user d-flex align-items-center flex-column" +
				(props.isActive ? " active" : "")
			}
			onClick={handleClick}
		>
			<div
				className="user-image mb-2"
				style={{
					backgroundImage: "url(" + props.image + ")",
				}}
			/>
			<div className="user-name">{props.name}</div>
			{props.isActive && props.pass?.length && (
				<div
					className={
						"pass-cont d-flex flex-column align-items-center mt-2" +
						(wrongPass ? " wrong" : "")
					}
				>
					<form
						onSubmit={(e) => {
							e.preventDefault();
							e.stopPropagation();
							checkPass();
						}}
					>
						<div className="d-flex align-items-center input-cont">
							<input
								type="password"
								className="password"
								value={pass}
								onChange={(e) => setPass(e.target.value)}
							/>
							<div
								className="ms-1 me-1 log-arrow"
								onClick={checkPass}
							>
								<FontAwesomeIcon icon={faArrowRight} />
							</div>
						</div>
					</form>
					<div
						className="d-flex align-items-center mt-3 change-user"
						onClick={(e) => {
							e.stopPropagation();
							props.close();
						}}
					>
						<div className="back-arrow">
							<FontAwesomeIcon icon={faArrowLeft} />
						</div>
						<div className="ms-2">Changer d'utilisateur</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default User;
