import React, { useState } from "react";

import "./App.css";
import Home from "./components/Home/Home";
import LogScreen from "./components/LogScreen/LogScreen";
import useTheme from "./components/Theme/useTheme";
import Wallpaper from "./components/Wallpaper/Wallpaper";
import { LocalesWrapper } from "./Locales/Locales";
import Storage from "./System/Storage/Storage";
import "./System/Files/Files.css";
import DefaultRoot from "./System/Storage/DefaultRoot";
import StoryManager from "./story/StoryManager";
import Boot from "./components/Boot/Boot";

export default function App() {
	// eslint-disable-next-line
	const [langCode, setLang] = useState(
		localStorage.getItem("lang-code") ||
			navigator.language ||
			navigator.userLanguage ||
			"en-EN"
	);
	// eslint-disable-next-line
	const [isLoading, setIsLoading] = useState(false);
	const [curUser, setCurUser] = useState(false);
	const [tempUser, setTempUser] = useState(false);
	const { theme } = useTheme(curUser ? curUser.theme : "dark");
	const showLogscreen = true;
	const fullscreen = false;
	const force_refresh = false;
	// eslint-disable-next-line
	const { BG, setBG } = useState();
	const [storyCont, setStoryCont] = useState(false);
	const [storage] = useState(
		new Storage(force_refresh ? DefaultRoot : false, false, forceReload)
	);
	const [story] = useState(
		new StoryManager(storage.read("/").story, setStoryCont, setCurUser)
	);
	const [isUpdating, setIsUpdating] = useState(false);
	// eslint-disable-next-line

	// eslint-disable-next-line
	function changeLang(code) {
		localStorage.setItem("lang-code", code);
		window.location.reload();
	}

	React.useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const boot = urlParams.get("boot");
		if (boot && !storyCont) {
			let text = boot?.length ? boot : "Booting";

			window.history.pushState({}, null, "/");

			setStoryCont(
				<Boot
					disk={storage}
					story={story}
					text={text}
					onBoot={() => {
						setStoryCont(false);
					}}
					duration={5}
				/>
			);
		}

		if (isUpdating) {
			setCurUser(storage.user());
			setIsUpdating(false);
		}
	}, [isUpdating, setIsUpdating, storage, story, setStoryCont, storyCont]);

	function forceReload() {
		if (!isUpdating) {
			setCurUser(storage.user());
			setIsUpdating(true);
		}
	}

	function changeUser(user, logged) {
		if (logged) {
			setCurUser(storage.user(user));
		} else if (user.pass && !logged) {
			setCurUser(false);
			setTempUser(user);
		} else if (!user.pass) {
			if (curUser) {
				setCurUser(false);
				setTempUser(user);
				setTimeout(() => {
					storage.user(user);
					setCurUser(false);
					setTempUser(false);
				}, 1000);
			} else {
				setCurUser(storage.user(user));
				setTempUser(false);
			}
		}
	}

	if (isLoading)
		return (
			<div className="vh-100 w-100 d-flex align-items-center justify-content-center">
				Loading
			</div>
		);

	document.querySelector("#root").classList.add("theme-" + theme);

	return (
		<LocalesWrapper code={langCode}>
			<div className="wrapper">
				<Wallpaper
					user={curUser ? curUser : undefined}
					isUpdating={isUpdating}
				/>
				{curUser || !showLogscreen ? (
					<Home
						disk={storage}
						user={curUser}
						story={story}
						setCurUser={changeUser}
					/>
				) : (
					<LogScreen
						fullScreen={fullscreen}
						tempUser={tempUser}
						disk={storage}
						story={story}
						setCurUser={changeUser}
					/>
				)}
				{storyCont}
			</div>
		</LocalesWrapper>
	);
}
