import React from "react";

import "./Wallpaper.css";

import barcelone from "./backgrounds/Barcelone.jpg";
import flamingos from "./backgrounds/Flamingos.jpg";
import marseille from "./backgrounds/Marseille.jpg";
import porquerolles from "./backgrounds/Porquerolles.jpg";

export const WallpapersList = [
	{
		id: 1,
		name: "Barcelone",
		image: barcelone,
	},
	{
		id: 2,
		name: "Flamingos",
		image: flamingos,
	},
	{
		id: 3,
		name: "Marseille",
		image: marseille,
	},
	{
		id: 4,
		name: "Porquerolles",
		image: porquerolles,
	},
];

function Wallpaper(props) {
	function getCurrent(id) {
		if (!id) return false;
		for (let x in WallpapersList)
			if (WallpapersList[x].id === id) return WallpapersList[x].image;
		return false;
	}

	let img = WallpapersList[0].image;
	if (props?.user) img = getCurrent(props.user?.wallpaper()?.id);

	return (
		<div
			className="Wallpaper"
			user={props.user}
			style={{
				backgroundImage: "url(" + img + ")",
			}}
		></div>
	);
}

export default Wallpaper;
