import React from "react";
// Font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkSquareAlt } from "@fortawesome/free-solid-svg-icons";
import "./ExplorerFolder.css";

function ExplorerFolder({ folder, onClick, isAlias }) {
	return (
		<div className="ExplorerFolder file  icon-cont" onClick={onClick}>
			<div
				className="icon folder-icon"
				style={{
					backgroundImage: folder.icon
						? "url(" + folder.icon + ")"
						: "",
				}}
			>
				{isAlias && (
					<FontAwesomeIcon
						className="alias"
						icon={faExternalLinkSquareAlt}
					/>
				)}
			</div>
			<div className="name file-name">{folder.name}</div>
		</div>
	);
}

export default ExplorerFolder;
