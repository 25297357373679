import React, { Component } from "react";
import { Editor, Viewer } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor.css";
import "./NoteEditor.css";

export default class NoteEditor extends Component {
	constructor(props) {
		super(props);

		this.editorRef = React.createRef();
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(e) {
		let editor = this.editorRef.current;
		let text = editor.getInstance().getMarkdown();
		if (this.props.updateNote) {
			let note = this.props.note;
			if (note.text !== text) {
				note.text = text;
				this.props.updateNote(note);
			}
		}
	}

	render() {
		return (
			<>
				{this.props.note.locked !== true ? (
					<Editor
						ref={this.editorRef}
						width={"100%"}
						height={"100%"}
						initialEditType={"wysiwyg"}
						hideModeSwitch={true}
						initialValue={this.props.note.text}
						events={{
							change: this.handleChange,
						}}
					/>
				) : (
					<Viewer
						ref={this.editorRef}
						width={"100%"}
						height={"100%"}
						initialEditType={"wysiwyg"}
						hideModeSwitch={true}
						initialValue={this.props.note.text}
					/>
				)}
			</>
		);
	}
}
