import React from "react";
import "./Icon.css";
// Font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkSquareAlt } from "@fortawesome/free-solid-svg-icons";
import { ICONS_PATH } from "../../System/Paths";

function Icon(props) {
	let icon = props.icon;

	function handleClick(e) {
		if (props.onClick) props.onClick(props.app, e);
	}

	if (props.icons && props.iconsFn) {
		let name = props.iconsFn();
		icon = props.icons[name];
	}
	if (!icon)
		icon =
			ICONS_PATH + (props.type === "folder" ? "folder.png" : "file.png");

	return (
		<div
			className={
				"icon-cont" +
				(props.isRunning ? " running" : "") +
				(props.isInDock ? " docked" : "") +
				(props.isInFolder ? " in-folder" : "")
			}
			onClick={handleClick}
		>
			<div
				className="icon"
				style={{
					backgroundImage: "url(" + icon + ")",
				}}
			>
				{props.isAlias && (
					<FontAwesomeIcon
						className="alias"
						icon={faExternalLinkSquareAlt}
					/>
				)}
			</div>
			<div className="active-dot"></div>
			<div className="file-name">{props.name}</div>
		</div>
	);
}

export default Icon;
