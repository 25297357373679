import React from "react";
import getApp from "../../../../applications/ApplicationsList";
import Storage from "../../../Storage/Storage";

const AppOpener = (props) => {
	let app = getApp(props.appName);

	let storage = new Storage();
	storage = storage.getAppStorage(app);

	if (!app) return false;

	return (
		<>
			<app.component
				setTitle={props.setTitle}
				isLoaded={props.isLoaded}
				isFocused={props.isFocused}
				appProps={props.appProps}
				storage={storage}
				launch={props.launch}
				endLoad={props.endLoad}
				story={props.story}
				disk={props.disk}
				history={props.history}
			/>
		</>
	);
};

export default AppOpener;
