import TrashApp from "../../../applications/Trash/TrashApp";
import avatar_d from "../assets/avatar-d.jpeg";
const date = Date.now();
const UnknownUser = {
	name: "u̷̡̘̭͐n̷͎̂̌̇̿͘k̵̡̝̮̓ͅw̸͚̥̬̄̏͝n̴̡͌͗̇̀",
	path: "unknown",
	type: "folder",
	hidden: true,
	pass: "56d883f00db3e9b3b90bbd8c09fb8f21234a5f23",
	image: avatar_d,
	appearance: {
		wallpaper: null,
		theme: "dark",
	},
	appsStorage: [],
	dock: {
		name: "Dock",
		path: "dock",
		type: "folder",
		hidden: true,
		files: [],
	},
	files: [
		{
			hidden: true,
			name: ".",
			type: "alias",
			path: "/users/unknown",
		},
		{
			...TrashApp,
			files: [
				{
					name: "Map_Gamebox",
					ext: "jpeg",
					type: "file",
					created: date,
				},
			],
		},
	],
};

export default UnknownUser;
