// need to do auto set id, modified & pinned when adding notes to NotesApp
const StoryNotes = [
	{
		name: "Konami",
		text: "?\n \n⬆ ⬆ ⬇ ⬇ ⬅ ➡ ⬅ ➡ 🅱 🅰",
		steps: [],
		locked: true,
	},
	{
		name: "Day1",
		text: "### Jour 1\n---\nJ'ai pu rentrer le code Konami dans la Gamebox!\n\nMalheureusement, la suite de l'énigme n'est pas disponible pour le moment...\n---\nDe nouveaux contenus seront disponible dans une prochaine mise à jour!",
		steps: ["konami"],
		locked: true,
	},
	{
		name: "Day2",
		text: "### Jour 2\n---\nMon ordniateur vient de redémarrer aprés avoir entré le code Konami dans la Gamebox",
		steps: ["konamiiii"],
		locked: true,
	},
];

export default StoryNotes;
