//import map from "./map.png"
//import maze from "./maze.png"
import maze from "./maze-short.png";
//import rick from "./rick.png"

export const MapConfig = {
	id: 1,
	file: maze,

	p_spawn: "#00ff00",
	p_angle: 2 * Math.PI * 0.75,
	empty: "#ffffff",
	wall: "#000000",

	// walls faces colors/sprites
	SO: "#CCCCCC",
	EA: "#BBBBBB",
	WE: "#AAAAAA",
	NO: "#999999",

	floor: "#000000",
	sky: "#000000",

	props: [
		{
			id: 3,
			name: "barrel",
			color: "#d23f28",
		},
	],
};
