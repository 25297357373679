export default class Camera {
	constructor(fov, screen) {
		this.fov = fov;
		this.proj_W = screen.width;
		this.proj_H = screen.height;
		this.proj_dist = this.proj_W / 2 / Math.tan(this.fov / 2);
		this.col_step = this.fov / this.proj_W;
		this.divided = {
			x: this.proj_W / 2,
			y: this.proj_H / 2,
		};
	}
}
